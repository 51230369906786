import { cloneDeep, isPlainObject } from 'lodash-es'
import qs from 'qs'
import { RouteLocationNormalized, RouteRecordNormalized } from 'vue-router'

export function setObjToUrlParams(baseUrl: string, obj: any): string {
    let parameters = ''
    for (const key in obj) {
        parameters += key + '=' + encodeURIComponent(obj[key]) + '&'
    }
    parameters = parameters.replace(/&$/, '')
    return /\?$/.test(baseUrl) ? baseUrl + parameters : baseUrl.replace(/\/?$/, '?') + parameters
}

// 深度合并
export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
    let key: string
    const res: any = cloneDeep(src)
    for (key in target) {
        res[key] = isPlainObject(res[key]) ? deepMerge(res[key], target[key]) : (res[key] = target[key])
    }
    return res
}

export function openWindow(url: string, opt?: { target?: '_self' | '_blank' | string; noopener?: boolean; noreferrer?: boolean }) {
    const { target = '__blank', noopener = true, noreferrer = true } = opt || {}
    const feature: string[] = []

    noopener && feature.push('noopener=yes')
    noreferrer && feature.push('noreferrer=yes')

    window.open(url, target, feature.join(','))
}

export function getRawRoute(route: RouteLocationNormalized): RouteLocationNormalized {
    if (!route) return route
    const { matched, ...opt } = route
    return {
        ...opt,
        matched: (matched
            ? matched.map((item) => ({
                  meta: item.meta,
                  name: item.name,
                  path: item.path
              }))
            : undefined) as RouteRecordNormalized[]
    }
}

//获取当前Url参数，key不传的话，返回所有参数
export function getQuery(key: string) {
    const url = window.location.href
    const queryString = url.split('?')[1]
    if (!queryString) {
        return null
    }
    const data = qs.parse(queryString)
    return key ? data[key] : data
}

export function getBizIdFromUrl() {
    const path = location.hash
    const redirect: any = getQuery('redirect')
    let arr = path.split('/')
    if (redirect) {
        arr = redirect.split('/')
    }

    if (arr.length >= 2) {
        return parseInt(arr[1], 10)
    } else {
        return 0
    }
}

//判断定义的数字是否是数组形式[1]，这种形式
export function isDefineNumber(val: string) {
    try {
        const parsedVal = JSON.parse(val)
        if (Array.isArray(parsedVal)) {
            return parsedVal.length > 0
        } else {
            return false
        }
    } catch (e) {
        return false
    }
}

//获取定义的数字是否是数组形式[1]，这种形式
export function getDefineNumber(val: string) {
    try {
        const parsedVal = JSON.parse(val)
        if (Array.isArray(parsedVal)) {
            return parsedVal[0]
        } else {
            return 0
        }
    } catch (e) {
        return 0
    }
}

// JavaScript实现文件大小转换、单位转换、toFixed、indexOf、substr、substring、B、KB、MB、GB
export function bytesToSize(size: any) {
    if (size < 0.1 * 1024) {
        //小于0.1KB，则转化成B
        size = size.toFixed(2) + "B";
    } else if (size < 0.1 * 1024 * 1024) {
        // 小于0.1MB，则转化成KB
        size = (size / 1024).toFixed(2) + "KB";
    } else if (size < 0.1 * 1024 * 1024 * 1024) {
        // 小于0.1GB，则转化成MB
        size = (size / (1024 * 1024)).toFixed(2) + "MB";
    } else {
        // 其他转化成GB
        size = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }

    // 转成字符串
    let sizeStr = size + "",
        // 获取小数点处的索引
        index = sizeStr.indexOf("."),
        // 获取小数点后两位的值
        dou = sizeStr.substr(index + 1, 2);

    // 判断后两位是否为00，如果是则删除00
    if (dou == "00") return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);

    return size;
}