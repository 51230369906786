import { AxiosError, AxiosInstance } from 'axios'

import { RetryRequest } from './types'

// 请求重试机制
export class AxiosRetry {
    // 重试
    async retry(axiosInstance: AxiosInstance, error: AxiosError) {
        const config = error.response?.config as any
        const { waitTime, count } = config?.requestOptions?.retryRequest as RetryRequest
        config.__retryCount = config.__retryCount || 0
        if (config.__retryCount >= count) {
            return Promise.reject(error)
        }
        config.__retryCount += 1
        //请求返回后config的header不正确造成重试请求失败,删除返回headers采用默认headers
        delete config.headers
        await this.delay(waitTime)

        return await axiosInstance(config)
    }

    // 延迟
    private delay(waitTime: number) {
        return new Promise((resolve) => setTimeout(resolve, waitTime))
    }
}
