import { isDevMode } from '@/utils/env'

// 系统默认的缓存时间，单位为天
export const DAY_NUMBER = 7

// 系统默认的缓存时间，单位为秒
export const DEFAULT_CACHE_TIME = 60 * 60 * 24 * DAY_NUMBER

// Aes加密密钥
export const cacheCipher = {
    key: '_11111000001111@',
    iv: '@11111000001111_'
}

// 是否使用aes加密系统缓存
export const enableStorageEncryption = !isDevMode()
