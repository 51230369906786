import { computed, unref } from 'vue'

import { useLocaleStoreWithOut } from '@/store/modules/locale'
import { LocaleType } from '@/types/config'

import { loadLocalePool, setHtmlPageLang } from './helper'
import { i18n } from './index'

interface LangModule {
    message: Recordable
    dateLocale: Recordable
    dateLocaleName: string
}

function setI18nLanguage(locale: LocaleType) {
    const localeStore = useLocaleStoreWithOut()

    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        ;(i18n.global.locale as any).value = locale
    }
    localeStore.setLocaleInfo({ locale })
    setHtmlPageLang(locale)
}

export function useLocale() {
    const localeStore = useLocaleStoreWithOut()
    const getLocale = computed(() => localeStore.getLocale)
    const getShowLocalePicker = computed(() => localeStore.getShowPicker)

    const getAntdLocale = computed((): any => {
        return i18n.global.getLocaleMessage<{ antdLocale: any }>(unref(getLocale))?.antdLocale ?? {}
    })

    // 改变语言
    async function changeLocale(locale: LocaleType) {
        const globalI18n = i18n.global
        const currentLocale = unref(globalI18n.locale)
        if (currentLocale === locale) {
            return locale
        }

        if (loadLocalePool.includes(locale)) {
            setI18nLanguage(locale)
            return locale
        }
        const langModule = ((await import(`./lang/${locale}.ts`)) as any).default as LangModule
        if (!langModule) return

        const { message } = langModule

        globalI18n.setLocaleMessage(locale, message)
        loadLocalePool.push(locale)

        setI18nLanguage(locale)
        return locale
    }

    return {
        getLocale,
        getShowLocalePicker,
        changeLocale,
        getAntdLocale
    }
}
