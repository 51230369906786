import { defineStore } from 'pinia'
import { Router } from 'vue-router'

import { getMyBizList } from '@/api/cmdb'
import { getDashboards } from '@/api/monitor'
import { MOA_AUTH_INFO } from '@/config/cache'
import { Platform } from '@/types/store'
import { getBizIdFromUrl } from '@/utils'
import { clearAuthCache, getAuthCache, setAuthCache } from '@/utils/auth'
import { webCookie } from '@/utils/cache'

import { store } from '../index'
import { useMultipleTabStore } from './multipleTab'

interface BusinessRoute {
    id: number
    name: string
    uid: string
    bizId: number
    bizCode: string
}

interface PlatformStore {
    platform?: number
    platformList: Platform[]
    businessRoutes: BusinessRoute[]
}

export const usePlatformStore = defineStore({
    id: 'app-platform',
    state: (): PlatformStore => ({
        platform: undefined,
        platformList: [],
        businessRoutes: []
    }),
    getters: {
        getPlatform(): number {
            //支持url参数传biz_id，然后切换对应的系统
            const bizId = getBizIdFromUrl()

            let id
            if (bizId) {
                id = Number(bizId)
            } else {
                id = this.platform || window.GLOBAL_ID
            }
            return id
        },
        getPlatformNoCache(): number {
            return window.GLOBAL_ID
        },
        getPlatformCode(): string {
            return this.platformList.find((item) => item.id === this.getPlatform)?.code || ''
        },
        getPlatformName(): string {
            return this.platformList.find((item) => item.id === this.getPlatform)?.name || ''
        },
        getPlatforms(): Platform[] {
            return this.platformList
        },
        getBusinessRoutes(): BusinessRoute[] {
            return this.businessRoutes
        }
    },
    actions: {
        getPlatformCodeById(id: number): string {
            return this.platformList.find((item) => item.id === id)?.code || ''
        },
        getPlatformNameById(id: number): string {
            return this.platformList.find((item) => item.id === id)?.name || ''
        },
        async getPlatformList(router: Router) {
            let list: any[] = []
            try {
                // list = await getMyBizList()
                list = window.getLsCacheItem(window.COMMON_VARS.BUSINESS_LIST);
                
            } catch (e: any) {
                if (e.message.indexOf('权限') > -1) {
                    clearAuthCache(true)
                    webCookie.remove(MOA_AUTH_INFO)
                    return 1
                }
            }
            this.platformList = list.filter((item) => item.status === 0)
            let id
            //支持url参数传biz_id，然后切换对应的系统
            const bizId = getBizIdFromUrl()
            if (bizId) {
                id = Number(bizId)
            } else {
                id = this.getPlatform && this.platformList.some((item) => item.id === this.getPlatform) ? this.getPlatform : this.platformList[0]?.id
            }

            if (id) await this.setPlatform(id, router)
        },
        async setPlatform(id: number, router: Router) {
            this.platform = id
            await this.initBusinessRoutes(router)
            // setAuthCache(PLATFORM_KEY, id)
            window.GLOBAL_ID = id
            window.GLOBAL_CODE = this.platformList.find((item) => item.id === id)?.code
            window.GLOBAL_NAME = this.platformList.find((item) => item.id === id)?.name

            // webCookie.set('currBiz', id)
            // webCookie.set('currBizCode', this.platformList.find((item) => item.id === id)?.code)
        },
        async setPlatformPlus(id: number, router: Router) {
            this.platform = id
            // setAuthCache(PLATFORM_KEY, id)
            console.log('setPlatform: ', id)
            window.GLOBAL_ID = id
            window.GLOBAL_CODE = this.platformList.find((item) => item.id === id)?.code
            window.GLOBAL_NAME = this.platformList.find((item) => item.id === id)?.name
            console.log('setPlatform1111: ', window.GLOBAL_ID, window.GLOBAL_CODE)

            // webCookie.set('currBiz', id)
            // webCookie.set('currBizCode', this.platformList.find((item) => item.id === id)?.code)
        },
        addBusinessRoutesHead(data: BusinessRoute) {
            this.businessRoutes.unshift(data)
        },
        addBusinessRoutes(data: BusinessRoute) {
            this.businessRoutes.push(data)
        },
        updataBusinessRoutes(data: BusinessRoute) {
            const index = this.businessRoutes.findIndex((item) => item.id === data.id)
            this.businessRoutes[index] = data
        },
        removeBusinessRoutes(id: number) {
            const index = this.businessRoutes.findIndex((item) => item.id === id)
            this.businessRoutes.splice(index, 1)
        },
        async initBusinessRoutes(router: Router) {
            const multipleTabStore = useMultipleTabStore()
            this.businessRoutes = []
            // if (!this.getPlatform) this.businessRoutes = []
            // else {
            //     const dashboards = await getDashboards({ biz_id: this.getPlatform, page: 1, size: 100000 }).then((res) => res.results)
            //     this.businessRoutes = dashboards.map(({ biz_id, biz_code, ...item }: any) => ({ ...item, bizId: biz_id, bizCode: biz_code }))
            // }

            // 这里清理业务路由标识的标签
            const removeTabs = multipleTabStore.getTabList.filter((item) => item.name === 'BusinessMonitor')
            removeTabs.forEach((item) => multipleTabStore.closeTab(item, router))
        }
    }
})

export function usePlatformStoreWithOut() {
    return usePlatformStore(store)
}
