import mitt from 'mitt'
import type { RouteLocationNormalized } from 'vue-router'

import { getRawRoute } from '@/utils'

const key = Symbol()

const emitter = mitt()

let lastChangeTab: RouteLocationNormalized

export function setRouteChange(lastChangeRoute: RouteLocationNormalized) {
    const r = getRawRoute(lastChangeRoute)
    emitter.emit(key, r)
    lastChangeTab = r
}

export function listenerRouteChange(callback: (route: RouteLocationNormalized) => void, immediate = true) {
    emitter.on(key, callback as any)
    immediate && lastChangeTab && callback(lastChangeTab)
}

export function removeTabChangeListener() {
    emitter.all.clear()
}
