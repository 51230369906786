export interface Cache<V = any> {
    value?: V
    timeoutId?: ReturnType<typeof setTimeout>
    time?: number // 创建时间
    alive?: number // 保活时长、单位毫秒（0是长久）
}

const NOT_ALIVE = 0

class Memory<T = any, V = any> {
    private cache: { [key in keyof T]?: Cache<V> } = {}
    private alive: number

    constructor(alive = NOT_ALIVE) {
        this.alive = alive * 1000
    }

    get getCache() {
        return this.cache
    }

    setCache(cache: any) {
        this.cache = cache
    }

    get<K extends keyof T>(key: K) {
        return this.cache[key]
    }

    set<K extends keyof T>(key: K, value: V, expires?: number) {
        let item = this.get(key)

        if (!expires || expires <= 0) {
            expires = this.alive
        }

        if (item) {
            if (item.timeoutId) {
                clearTimeout(item.timeoutId)
                item.timeoutId = undefined
            }

            item.value = value
        } else {
            item = { value, alive: expires }
            this.cache[key] = item
        }

        if (!expires) return value

        const now = new Date().getTime()
        item.time = expires > now ? expires : now + expires
        item.timeoutId = setTimeout(
            () => {
                this.remove(key)
            },
            expires > now ? expires - now : expires
        )

        return value
    }

    remove<K extends keyof T>(key: K) {
        const item = this.get(key)
        Reflect.deleteProperty(this.cache, key)
        if (item) {
            clearTimeout(item.timeoutId!)
            return item.value
        }
    }

    // 重置之前的缓存
    resetCache(cache: { [K in keyof T]: Cache }) {
        Object.keys(cache).forEach((key) => {
            const k = key as any as keyof T
            const item = cache[k]
            if (item && item.time) {
                const now = new Date().getTime()
                const expire = item.time
                if (expire > now) {
                    this.set(k, item.value, expire)
                }
            }
        })
    }

    clear() {
        Object.keys(this.cache).forEach((key) => {
            const item = this.cache[key as keyof T]
            if (item) item.timeoutId && clearTimeout(item.timeoutId)
        })
        this.cache = {}
    }
}

export default Memory
