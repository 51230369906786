export const theme = {
    token: {
        colorPrimary: '#3A84FF',
        borderRadius: 0,
        fontSize: 12,
        wireframe: false,
        colorInfo: '#3A84FF',
        colorBorder: '#DFE0E5',
        colorTextBase: '#63656e',
        colorTextPlaceholder: '#979BA5',
        fontFamily: 'PingFang SC,Lantinghei SC,Helvetica Neue,Helvetica,Arial,Microsoft YaHei,微软雅黑,STHeitiSC-Light,simsun,宋体,WenQuanYi Zen Hei,WenQuanYi Micro Hei,sans-serif;',
    }
}
