import { computed } from 'vue'

import useAppStore from '@/store/modules/app'
import type { TransitionSetting } from '#/config'

export function useTransitionSetting() {
    const appStore = useAppStore()

    const getEnableTransition = computed(() => appStore.getTransitionSetting?.enable)

    const getOpenNProgress = computed(() => appStore.getTransitionSetting?.openNProgress)

    const getOpenPageLoading = computed((): boolean => {
        return !!appStore.getTransitionSetting?.openPageLoading
    })

    const getBasicTransition = computed(() => appStore.getTransitionSetting?.basicTransition)

    function setTransitionSetting(transitionSetting: Partial<TransitionSetting>) {
        appStore.setProjectConfig({ transitionSetting })
    }

    return {
        setTransitionSetting,
        getEnableTransition,
        getOpenNProgress,
        getOpenPageLoading,
        getBasicTransition
    }
}
