import type { Router, RouteRecordRaw } from 'vue-router'

import { PageEnum } from '@/enums/pageEnum'
import { commonDataStoreWithOut } from '@/store/modules/commonData'
import { usePermissionStoreWithOut } from '@/store/modules/permission'
import { usePlatformStoreWithOut } from '@/store/modules/platform'
import { useUserStoreWithOut } from '@/store/modules/user'

import { RootRoute } from '../routes'
import { PAGE_NOT_FOUND_ROUTE } from '../routes/basic'

const LOGIN_PATH = PageEnum.BASE_LOGIN

const ROOT_PATH = RootRoute.path

const whitePathList: PageEnum[] = [LOGIN_PATH]

export function createPermissionGuard(router: Router) {
    const userStore = useUserStoreWithOut()
    const platformStore = usePlatformStoreWithOut()
    const permissionStore = usePermissionStoreWithOut()
    const commonData = commonDataStoreWithOut()

    router.beforeEach(async (to, from, next) => {
        if (from.path === ROOT_PATH && to.path === PageEnum.BASE_HOME && userStore.getUserInfo.homePath && userStore.getUserInfo.homePath !== PageEnum.BASE_HOME) {
            next(userStore.getUserInfo.homePath)
            return
        }

        const token = window.getToken()
        //白名单直接跳转
        if (whitePathList.includes(to.path as PageEnum)) {
            if (to.path === LOGIN_PATH && token) {
                try {
                    await userStore.afterLoginAction()
                    next((to.query?.redirect as string) || '/')
                    return
                } catch {
                    /* empty */
                }
            }
            next()
            return
        }

        if (!token) {
            if (to.meta.ignoreAuth) {
                next()
                return
            }

            // 重定向到登录界面
            const redirectData: { path: string; replace: boolean; query?: Recordable<string> } = { path: LOGIN_PATH, replace: true }
            if (to.path) {
                redirectData.query = { ...redirectData.query, redirect: to.path }
            }

            next(redirectData)
            return
        }

        // 处理完登录后跳转到404页面
        if (from.path === LOGIN_PATH && to.name === PAGE_NOT_FOUND_ROUTE.name && to.fullPath !== (userStore.getUserInfo.homePath || PageEnum.BASE_HOME)) {
            next(userStore.getUserInfo.homePath || PageEnum.BASE_HOME)
            return
        }

        // 用户信息为空则获取用户信息，获取失败，跳转到登录界面
        // if (userStore.getLastUpdateTime === 0) {
        //     try {
        //         await userStore.getUserInfoAction()

        // 处理当前平台逻辑
        if (!platformStore.getPlatform || !platformStore.getPlatforms.length) {
            const result = await platformStore.getPlatformList(router)
            // 如果是权限问题，则停止下面路由的加载
            if (result === 1) {
                next()
                return
            }
        }

                // // 读取通用数据
                // if (!commonData.portRange.length) {
                //     await commonData.getCommonData()
                // }
        //     } catch (err) {
        //         next()
        //         return
        //     }
        // }

        // 如果已经动态添加路由则直接跳转
        if (permissionStore.getIsDynamicAddedRoute) {
            next()
            return
        }

        // 动态添加路由
        const routes = await permissionStore.buildRoutesAction()

        routes.forEach((route) => {
            router.addRoute(route as unknown as RouteRecordRaw)
        })

        router.addRoute(PAGE_NOT_FOUND_ROUTE as unknown as RouteRecordRaw)

        permissionStore.setDynamicAddedRoute(true)

        if (to.name === PAGE_NOT_FOUND_ROUTE.name) {
            // 动态添加路由后，此处应当重定向到fullPath，否则会加载404页面内容
            next({ path: to.fullPath, replace: true, query: to.query })
        } else {
            const redirectPath = (from.query.redirect || to.path) as string
            const redirect = decodeURIComponent(redirectPath)
            const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect }
            next(nextData)
        }
    })
}
