import { ProjectConfig } from '@/types/config'
import { CacheTypeEnum } from './cache'
import { MenuModeEnum, MenuTypeEnum, TriggerEnum } from '@/enums/menuEnum'
import { ContentEnum, PermissionModeEnum, RouterTransitionEnum, ThemeEnum } from '@/enums/appEnum'

const setting: ProjectConfig = {
    showLogo: true, // 是否显示logo
    showAppSwitch: true, // 是否显示应用切换
    showFooter: false, // 是否显示页脚
    openKeepAlive: true, // 开启缓存
    canEmbedIFramePage: true, // 是否可以嵌入iframe页面
    showBreadCrumb: true, // 是否显示面包屑
    showBreadCrumbIcon: true, // 是否显示面包屑图标

    permissionMode: PermissionModeEnum.ROUTE_MAPPING, // 权限模式

    // 权限缓存存储在sessionStorage或localStorage中
    permissionCacheType: CacheTypeEnum.LOCAL,

    fullContent: false, // 是否取消菜单、顶部、多选项卡页面显示，以便可能嵌入到其他系统中
    contentMode: ContentEnum.FULL,
    grayMode: false, // 网站灰色模式，开放可能的哀悼日期

    closeMessageOnSwitch: false,
    removeAllHttpPending: false,

    // header 配置
    headerSetting: {
        show: true,
        theme: ThemeEnum.LIGHT,
        showFullScreen: true,
        fixed: true,
        showNotice: false,
        showSearch: false
    },

    // 菜单配置
    menuSetting: {
        type: MenuTypeEnum.SIDEBAR, // 菜单类型
        mode: MenuModeEnum.INLINE, // 菜单模式
        theme: ThemeEnum.DARK, // 菜单主题
        show: true, // 是否显示菜单
        collapsed: false, // 菜单是否折叠
        menuWidth: 240, // 菜单宽度
        fixed: true, // 固定菜单
        topMenuAlign: 'center', // 顶部菜单对齐方式
        trigger: TriggerEnum.HEADER // 触发菜单方式
    },

    // 过度配置
    transitionSetting: {
        enable: true, // 是否开启过度动画
        basicTransition: RouterTransitionEnum.FADE_SIDE,
        openPageLoading: false, // 关闭页面级的loading
        openNProgress: true
    },

    // 多标签配置
    multiTabsSetting: {
        cache: false, // 是否开启缓存
        show: true, // 是否显示
        canDrag: true // 是否支持拖拽
    }
}

export default setting
