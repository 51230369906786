import defHttp from '@/utils/http'
import { getStartAnd5MinAgo } from '@/utils/time'

// 业务看板
interface Dashboard {
    id: number
    name: string
    uid: string
    biz_id: number
    biz_code: string
}

// 获取业务看板列表
export const getDashboards = (params: any) => defHttp.get({ url: 'monitor/v1/dashboard', params })

export async function getToken() {
    return defHttp.get({ url: 'monitor/v1/token' })
}

//安全配置列表
export async function getSecurityGroupList(params: any) {
    return defHttp.get(
        {
            url: 'firewall/v1/security_group?order=-id',
            params: { ...params }
        },
        { errorMessageMode: 'none' }
    )
}

//获取一条安全配置
export async function getSecurityGroup(id: any) {
    return defHttp.get({
        url: `firewall/v1/security_group/${id}`
    })
}

//添加安全配置
export async function createSecurityGroup(data: any) {
    return defHttp.post({
        url: 'firewall/v1/security_group',
        method: 'post',
        data
    })
}

//更新安全配置
export async function updateSecurityGroup(id: any, data: any) {
    return defHttp.request({
        url: `firewall/v1/security_group/${id}`,
        method: 'put',
        data
    })
}

//删除安全配置
export async function deleteSecurityGroup(id: any, data: any) {
    return defHttp.request({
        url: `firewall/v1/security_group/${id}`,
        method: 'delete',
        data
    })
}

//安全规则列表
export async function getSecurityRuleList(params: any) {
    return defHttp.get(
        {
            url: 'firewall/v1/security_rule?order=-id',
            params: { ...params }
        },
        { errorMessageMode: 'none' }
    )
}

//获取一条安全规则
export async function getSecurityRule(id: any) {
    return defHttp.get({
        url: `firewall/v1/security_rule/${id}`
    })
}

//添加安全规则
export async function createSecurityRule(data: any) {
    return defHttp.post({
        url: 'firewall/v1/security_rule',
        method: 'post',
        data
    })
}

//更新安全规则
export async function updateSecurityRule(id: any, data: any) {
    return defHttp.request({
        url: `firewall/v1/security_rule/${id}`,
        method: 'put',
        data
    })
}

//删除安全规则
export async function deleteSecurityRule(id: any, data: any) {
    return defHttp.request({
        url: `firewall/v1/security_rule/${id}`,
        method: 'delete',
        data
    })
}

//导入规则
export async function importRule(data: any) {
    return defHttp.request({
        url: `firewall/v1/security_rule_import`,
        method: 'post',
        data
    })
}

//获取一条安全规则
export async function getSecurityProtocol() {
    return defHttp.get({
        url: `firewall/v1/protocol`
    })
}

//获取端口范围
export async function getSecurityPortRange() {
    return defHttp.get({
        url: `firewall/v1/port_range`
    })
}

//获取ip组
export async function getSecurityIPGroup(params: any) {
    return defHttp.get(
        {
            url: 'firewall/v1/ip_group?order=-id',
            params: { ...params }
        },
        { errorMessageMode: 'none' }
    )
}

//获取一条IP组配置
export async function getSecurityIP(id: any) {
    return defHttp.get({
        url: `firewall/v1/ip_group/${id}`
    })
}

//添加IP组配置
export async function createSecurityIP(data: any) {
    return defHttp.post({
        url: 'firewall/v1/ip_group',
        method: 'post',
        data
    })
}

//更新IP组配置
export async function updateSecurityIP(id: any, data: any) {
    return defHttp.request({
        url: `firewall/v1/ip_group/${id}`,
        method: 'put',
        data
    })
}

//删除IP组配置
export async function deleteSecurityIP(id: any, data: any) {
    return defHttp.request({
        url: `firewall/v1/ip_group/${id}`,
        method: 'delete',
        data
    })
}

//获取实例映射
export async function getSecurityInsMaps(params: any) {
    return defHttp.get(
        {
            url: 'firewall/v1/security_mapping?order=-id',
            params: { ...params }
        },
        { errorMessageMode: 'none' }
    )
}

//获取一条实例映射
export async function getSecurityInsMap(id: any) {
    return defHttp.get({
        url: `firewall/v1/security_mapping/${id}`
    })
}

//添加实例映射
export async function createSecurityInsMap(data: any) {
    return defHttp.post({
        url: 'firewall/v1/security_mapping',
        method: 'post',
        data
    })
}

//更新实例映射
export async function updateSecurityInsMap(id: any, data: any) {
    return defHttp.request({
        url: `firewall/v1/security_mapping/${id}`,
        method: 'put',
        data
    })
}

//删除实例映射
export async function deleteSecurityInsMap(id: any, data: any) {
    return defHttp.request({
        url: `firewall/v1/security_mapping/${id}`,
        method: 'delete',
        data
    })
}

//获取操作记录列表
export async function getActHistorys(params: any) {
    return defHttp.get(
        {
            url: 'firewall/v1/action_history?order=-id',
            params: { ...params }
        },
        { errorMessageMode: 'none' }
    )
}

//获取一条操作记录
export async function getActHistory(id: any) {
    return defHttp.get({
        url: `firewall/v1/action_history/${id}`
    })
}

//添加实例映射测试规则
export async function createInsMapTest(id: string | number, data: any) {
    return defHttp.post({
        url: `firewall/v1/security_mapping/${id}/test_rule`,
        method: 'post',
        data
    })
}

export async function createInsMapTestHost(id: string | number) {
    return defHttp.post({
        url: `firewall/v1/security_mapping/${id}/publish_host`,
        method: 'post',
        data: {}
    })
}

export async function createInsMapTestRule(id: string | number) {
    return defHttp.post({
        url: `firewall/v1/security_mapping/${id}/publish_rule`,
        method: 'post',
        data: {}
    })
}
