import { LAYOUT } from '@/router/constant'
import type { AppRouteRecordRaw } from '@/router/types'

const iam: AppRouteRecordRaw[] = [
    {
        path: '/iam',
        name: 'iam',
        component: LAYOUT,
        redirect: '/iam/template',
        meta: {
            icon: 'gg:template',
            title: '权限模板',
            hideChildrenInMenu: true
        },
        children: [
            {
                path: 'template',
                name: 'iamTemplate',
                component: () => import('@/views/iamTemplate/index.vue'),
                meta: {
                    icon: 'gg:template',
                    title: '权限模板',
                    hideChildrenInMenu: true,
                    affix: true,
                    hideBreadcrumb: true,
                    currentActiveMenu: '/iam/template'
                },
                children: [
                    {
                        path: 'add/:id',
                        name: 'iamTemplateAdd',
                        component: () => import('@/views/iamTemplate/AddOperate.vue'),
                        meta: {
                            dynamicLevel: 1,
                            title: '新增权限模板',
                            currentActiveMenu: '/iam/template',
                            realPath: '/iam/template/add/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'iamTemplateEdit',
                        component: () => import('@/views/iamTemplate/EditOperate.vue'),
                        meta: {
                            // dynamicLevel: 1,
                            title: '编辑权限模板',
                            currentActiveMenu: '/iam/template',
                            realPath: '/iam/template/edit/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    },
                    {
                        path: 'clone/:id',
                        name: 'iamTemplateClone',
                        component: () => import('@/views/iamTemplate/CloneOperate.vue'),
                        meta: {
                            // dynamicLevel: 1,
                            title: '克隆权限模板',
                            currentActiveMenu: '/iam/template',
                            realPath: '/iam/template/clone/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    }
                ]
            },
            {
                path: 'user',
                name: 'iamUser',
                component: () => import('@/views/iamUser/index.vue'),
                meta: {
                    icon: 'material-symbols:person',
                    title: '用户管理',
                    hideChildrenInMenu: true,
                    currentActiveMenu: '/iam/user'
                }
            },
            {
                path: 'group',
                name: 'iamGroup',
                component: () => import('@/views/iamGroup/index.vue'),
                meta: {
                    icon: 'material-symbols:group',
                    title: '用户组',
                    hideChildrenInMenu: true,
                    affix: true,
                    hideBreadcrumb: true,
                    currentActiveMenu: '/iam/group'
                },
                children: [
                    {
                        path: 'add/:id',
                        name: 'iamGroupAdd',
                        component: () => import('@/views/iamGroup/AddOperate.vue'),
                        meta: {
                            dynamicLevel: 1,
                            title: '新增用户组',
                            currentActiveMenu: '/iam/group',
                            realPath: '/iam/group/add/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'iamGroupEdit',
                        component: () => import('@/views/iamGroup/EditOperate.vue'),
                        meta: {
                            // dynamicLevel: 1,
                            title: '编辑用户组',
                            currentActiveMenu: '/iam/group',
                            realPath: '/iam/group/edit/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    },
                    {
                        path: 'clone/:id',
                        name: 'iamGroupClone',
                        component: () => import('@/views/iamGroup/CloneOperate.vue'),
                        meta: {
                            // dynamicLevel: 1,
                            title: '克隆用户组',
                            currentActiveMenu: '/iam/group',
                            realPath: '/iam/group/clone/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    }
                ]
            },
            {
                path: 'token',
                name: 'iamToken',
                component: () => import('@/views/iamToken/index.vue'),
                meta: {
                    icon: 'oui:token-key',
                    title: 'ApiToken',
                    hideChildrenInMenu: true,
                    affix: true,
                    hideBreadcrumb: true,
                    currentActiveMenu: '/iam/token'
                },
                children: [
                    {
                        path: 'add/:id',
                        name: 'iamTokenAdd',
                        component: () => import('@/views/iamToken/AddOperate.vue'),
                        meta: {
                            dynamicLevel: 1,
                            title: '新增ApiToken',
                            currentActiveMenu: '/iam/token',
                            realPath: '/iam/token/add/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'iamTokenEdit',
                        component: () => import('@/views/iamToken/EditOperate.vue'),
                        meta: {
                            // dynamicLevel: 1,
                            title: '编辑ApiToken',
                            currentActiveMenu: '/iam/token',
                            realPath: '/iam/token/edit/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    },
                    {
                        path: 'clone/:id',
                        name: 'iamTokenClone',
                        component: () => import('@/views/iamToken/CloneOperate.vue'),
                        meta: {
                            // dynamicLevel: 1,
                            title: '克隆ApiToken',
                            currentActiveMenu: '/iam/token',
                            realPath: '/iam/token/clone/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    }
                ]
            },
            {
                path: 'superuser',
                name: 'iamSuperuser',
                component: () => import('@/views/iamSuperuser/index.vue'),
                meta: {
                    icon: 'ri:admin-fill',
                    title: '超级管理员',
                    hideChildrenInMenu: true,
                    affix: true,
                    hideBreadcrumb: true,
                    currentActiveMenu: '/iam/superuser'
                },
                children: [
                    {
                        path: 'add/:id',
                        name: 'iamSuperuserAdd',
                        component: () => import('@/views/iamSuperuser/AddOperate.vue'),
                        meta: {
                            dynamicLevel: 1,
                            title: '新增超级管理员',
                            currentActiveMenu: '/iam/superuser',
                            realPath: '/iam/superuser/add/:id',
                            hideMenu: true,
                            hideBreadcrumb: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/history',
        name: 'history',
        component: LAYOUT,
        redirect: '/history/action',
        meta: {
            icon: 'gg:template',
            title: '操作审计',
            hideChildrenInMenu: true
        },
        children: [
            {
                path: 'action',
                name: 'historyAction',
                component: () => import('@/views/historyAct/index.vue'),
                meta: {
                    icon: 'material-symbols:list',
                    title: '操作审计',
                    hideChildrenInMenu: true,
                    hideBreadcrumb: true,
                    currentActiveMenu: '/history/action'
                }
            },
            {
                path: 'token',
                name: 'historyToken',
                component: () => import('@/views/historyToken/index.vue'),
                meta: {
                    icon: 'jam:task-list-f',
                    title: 'Token使用情况',
                    hideChildrenInMenu: true,
                    hideBreadcrumb: true,
                    currentActiveMenu: '/history/token'
                }
            },
        ]
    }
]

export default iam
