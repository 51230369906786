import { createPinia } from 'pinia'
import type { App } from 'vue'

const store = createPinia()

export { store }

function setupStore(app: App) {
    app.use(store)
}

export default setupStore
