import { intersection, isArray } from 'lodash-es'
import type { RouteRecordRaw } from 'vue-router'

import projectSetting from '@/config/project'
import { PermissionModeEnum } from '@/enums/appEnum'
import { RoleEnum } from '@/enums/roleEnum'
import { resetRouter, router } from '@/router'
import useAppStore from '@/store/modules/app'
import { useMultipleTabStore } from '@/store/modules/multipleTab'
import { usePermissionStore } from '@/store/modules/permission'
import { useUserStore } from '@/store/modules/user'

import { useTabs } from './useTabs'

// 用户权限相关操作
export function usePermission() {
    const userStore = useUserStore()
    const appStore = useAppStore()
    const permissionStore = usePermissionStore()
    const { closeAll } = useTabs(router)

    // 修改权限模式
    async function togglePermissionMode() {
        appStore.setProjectConfig({
            permissionMode: appStore.projectConfig?.permissionMode === PermissionModeEnum.BACK ? PermissionModeEnum.ROUTE_MAPPING : PermissionModeEnum.BACK
        })
        location.reload()
    }

    // 重置和重新获得权限资源信息
    async function resume() {
        const tabStore = useMultipleTabStore()
        tabStore.clearCacheTabs()
        resetRouter()
        const routes = await permissionStore.buildRoutesAction()
        routes.forEach((route) => {
            router.addRoute(route as unknown as RouteRecordRaw)
        })
        permissionStore.setLastBuildMenuTime()
        closeAll()
    }

    // 判断是否有权限
    function hasPermission(value?: RoleEnum | RoleEnum[] | string | string[], def = true): boolean {
        if (!value) {
            return def
        }

        const permMode = projectSetting.permissionMode

        if ([PermissionModeEnum.ROUTE_MAPPING, PermissionModeEnum.ROLE].includes(permMode)) {
            if (!isArray(value)) {
                return userStore.getRoleList?.includes(value as RoleEnum)
            }
            return (intersection(value, userStore.getRoleList) as RoleEnum[]).length > 0
        }

        if (PermissionModeEnum.BACK === permMode) {
            const allCodeList = permissionStore.getPermCodeList as string[]
            if (!isArray(value)) {
                return allCodeList.includes(value)
            }
            return (intersection(value, allCodeList) as string[]).length > 0
        }
        return true
    }

    // 改变角色
    async function changeRole(roles: RoleEnum | RoleEnum[]): Promise<void> {
        if (projectSetting.permissionMode !== PermissionModeEnum.ROUTE_MAPPING) {
            throw new Error('Please switch PermissionModeEnum to ROUTE_MAPPING mode in the configuration to operate!')
        }

        if (!isArray(roles)) {
            roles = [roles]
        }
        userStore.setRoleList(roles)
        await resume()
    }

    // 刷新菜单数据
    async function refreshMenu() {
        resume()
    }

    return { changeRole, hasPermission, togglePermissionMode, refreshMenu }
}
