import { set } from 'lodash-es'

import type { LocaleType } from '#/config'

// 判定i8n是否已经加载过语言包
export const loadLocalePool: LocaleType[] = []

export function setHtmlPageLang(locale: LocaleType) {
    document.querySelector('html')?.setAttribute('lang', locale)
}

export function setLoadLocalePool(cb: (loadLocalePool: LocaleType[]) => void) {
    cb(loadLocalePool)
}

export function genMessage(langs: Record<string, Record<string, any>>, prefix = 'lang') {
    const obj: Recordable = {}

    Object.keys(langs).forEach((key) => {
        const langFileModule = langs[key].default
        let fileName = key.replace(`./${prefix}/`, '').replace(/^\.\//, '')
        const lastIndex = fileName.lastIndexOf('.')
        fileName = fileName.substring(0, lastIndex)
        const keyList = fileName.split('/')
        const moduleName = keyList.shift()
        const objKey = keyList.join('.')

        if (moduleName) {
            if (objKey) {
                set(obj, moduleName, obj[moduleName] || {})
                set(obj[moduleName], objKey, langFileModule)
            } else {
                set(obj, moduleName, langFileModule || {})
            }
        }
    })

    return obj
}
