import { getSecurityGroupList, getSecurityIPGroup, getSecurityPortRange, getSecurityProtocol } from '@/api/firewall'
import { getPromQLLabels, getRules, getRulesGroup } from '@/api/monitor'
import mock from '@/synthesize/mock'

const valueRegExp = /^[0-9a-zA-Z_]{1,}$/
const chineseRegExp = /[\u4e00-\u9fa5]/

//告警规则系列接口
const monitorGetRules = (data: any) => {
    return getRules(data)
}

//获取告警接收组
const monitorGetRulesGroup = (data: any) => {
    return getRulesGroup(data)
}

//获取告警接收组
const getPromQLLabel = (data: any = {}) => {
    return getPromQLLabels(data)
}

//获取协议
const getSecurityProtocols = () => {
    return getSecurityProtocol()
}

//获取端口范围
const getSecurityPortRanges = () => {
    return getSecurityPortRange()
}

//获取端口范围s
const getSecurityIPGroups = (params: any) => {
    return getSecurityIPGroup(params)
}

//获取安全配置列表
const getSecurityGroupLists = (data: any) => {
    return getSecurityGroupList(data)
        .then((res) => {
            return {
                success: true,
                data: res.results,
                total: res.count
            }
        })
        .catch(() => ({ data: [], success: false, total: 0 }))
}

// 校验单个标签格式是否正确
function isTagValid(tag: any) {
    const numberRegExp = /^\d+$/
    const fractionRegex = /^(\d+)\/(\d+)$/
    tag = tag || ''
    return {
        isCorrectFormat: numberRegExp.test(tag.toString()) || fractionRegex.test(tag.toString()),
        isLengthAllowed: tag.toString().length <= 64
    }
}

// 校验单个标签格式是否正确
function isTagCommonValid(tag: any) {
    const contentRegExp = /^[a-zA-Z_][\w]*={1}[^=]+$/
    return {
        isCorrectFormat: contentRegExp.test(tag.toString()),
        isLengthAllowed: tag.toString().length <= 64
    }
}

// 校验所有标签格式
function isValidFormat() {
    return {
        validator(_: any, value: any) {
            const isInvalid =
                value &&
                value.some((tag: any) => {
                    const { isCorrectFormat, isLengthAllowed } = isTagCommonValid(tag)
                    if (!isCorrectFormat || !isLengthAllowed) {
                        return true
                    }
                })
            return isInvalid ? Promise.reject(new Error('标签只能包含字母、数字、下划线，请修改！')) : Promise.resolve()
        }
    }
}

// 校验所有标签格式
function isValidTags() {
    return {
        required: true,
        trigger: 'change',
        message: '请选择白名单',
        validator(_: any, value: any) {
            const isInvalid = value && value.length === 0
            return isInvalid ? Promise.reject(new Error('请选择白名单')) : Promise.resolve()
        }
    }
}

// 校验QL
function isValidQL() {
    return {
        required: true,
        validator(_: any, value: any) {
            if (chineseRegExp.test(value)) {
                return Promise.reject(new Error('PromQL不能包含中文！'))
            }

            const isInvalid = !value
            return isInvalid ? Promise.reject(new Error('请输入PromQL！')) : Promise.resolve()
        }
    }
}

// 校验所有标签格式
function isValidTagsForShield() {
    return {
        required: true,
        trigger: 'change',
        validator(_: any, value: any) {
            let isInvalid = false
            if (value.destination.length === 0) {
                isInvalid = true
            } else {
                if (value.destination.length === 1 && value.destination[0] === '-1/-1') {
                    isInvalid = false
                } else {
                    for (let i = 0; i < value.destination.length; i++) {
                        if (!isTagValid(value.destination[i]).isCorrectFormat) {
                            isInvalid = true
                            break
                        }
                    }
                }
            }

            return isInvalid ? Promise.reject(new Error('标签格式应数字或者数字/数字，请修改')) : Promise.resolve()
        }
    }
}

// 校验所有标签格式
function isValidPort() {
    return {
        required: true,
        trigger: 'change',
        asyncValidator: (rule: any, value: any) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    let isInvalid = false
                    if (value.destination.length === 0) {
                        isInvalid = true
                    } else {
                        isInvalid = false
                    }
                    if (isInvalid) {
                        reject(new Error('请选择端口范围'))
                    } else {
                        resolve('')
                    }
                }, 400)
            })
        }
    }
}

function isValidIP(ip: string) {
    return {
        trigger: 'change',
        required: true,
        validator(_: any, value: any) {
            const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(\/\d+)*$/img;
            let isInvalid = true
            if (reg.test(value)) {
                isInvalid = false
            }
            return isInvalid ? Promise.reject(new Error('请输入正确的实例IP，必须是合法的IP地址')) : Promise.resolve()
        }
    }
}

//校验IP IP组
function isValidIPSelect() {
    return {
        trigger: 'change, blur',
        required: true,
        // asyncValidator: (rule: any, value: any) => {
        //     console.log("🚀 ~ file: business.ts:219 ~ isValidIPSelect ~ value:", value)
        //     return new Promise((resolve, reject) => {
        //         setTimeout(() => {
        //             let isInvalid = false
        //             if(value.dynamic.length > 0 || value.static.length > 0 || value.topology.length > 0){
        //                 isInvalid = false
        //             }else{
        //                 isInvalid = true
        //             }
        //             if (isInvalid) {
        //                 reject(new Error('请选择IP'))
        //             } else {
        //                 resolve('')
        //             }
        //         }, 1000)
        //     })
        // }
        validator(_: any, value: any) {
            let isInvalid = false
            if (value.dynamic.length > 0 || value.static.length > 0 || value.topology.length > 0) {
                isInvalid = false
            } else {
                isInvalid = true
            }

            return isInvalid ? Promise.reject(new Error('请选择IP！')) : Promise.resolve()
        }
    }
}

//检验上传文件
function isValidFile() {
    return {
        trigger: 'change, blur',
        required: true,
        // asyncValidator: (rule: any, value: any) => {
        //     console.log("🚀 ~ file: business.ts:219 ~ isValidIPSelect ~ value:", value)
        //     return new Promise((resolve, reject) => {
        //         setTimeout(() => {
        //             let isInvalid = false
        //             if(value.dynamic.length > 0 || value.static.length > 0 || value.topology.length > 0){
        //                 isInvalid = false
        //             }else{
        //                 isInvalid = true
        //             }
        //             if (isInvalid) {
        //                 reject(new Error('请选择IP'))
        //             } else {
        //                 resolve('')
        //             }
        //         }, 1000)
        //     })
        // }
        validator(_: any, value: any) {
            let isInvalid = false
            if (value) {
                isInvalid = false
            } else {
                isInvalid = true
            }

            return isInvalid ? Promise.reject(new Error('请上传文件！')) : Promise.resolve()
        }
    }
}

//校验IP IP组
function isValidIPs() {
    return {
        trigger: 'change',
        validator(_: any, value: any) {
            let isInvalid = false
            value = (value || '').trim()
            if (value) {
                const ips = value.split('\n')
                for (let i = 0; i < ips.length; i++) {
                    if (!isValidIP(ips[i])) {
                        isInvalid = true
                        break
                    }
                }
            }

            return isInvalid ? Promise.reject(new Error('请输入合法的IP，多个IP用请回车换行！')) : Promise.resolve()
        }
    }
}

//校验IP IP组
function isValidSubject() {
    return {
        required: true,
        trigger: 'change',
        validator(_: any, value: any) {
            let isInvalid = false
            let ipPass = false

            if (value.source.ip && value.source.ip.length > 0) {
                for (let i = 0; i < value.source.ip.length; i++) {
                    if (!isValidIP(value.source.ip[i])) {
                        isInvalid = true
                        break
                    }
                }
                if (!isInvalid) {
                    ipPass = true
                }
            }

            if (ipPass) {
                return isInvalid ? Promise.reject(new Error('请输入合法的IP或者选择IP组，请修改')) : Promise.resolve()
            }

            if (value.source.ip_set && value.source.ip_set.length > 0) {
                isInvalid = false
            } else {
                isInvalid = true
            }

            return isInvalid ? Promise.reject(new Error('请输入合法的IP或者选择IP组，请修改')) : Promise.resolve()
        }
    }
}

//校验IP IP组
function isValidSubjectC() {
    return {
        required: true,
        trigger: 'change',
        validator(_: any, value: any) {
            console.log('🚀 ~ file: business.ts:170 ~ validator ~ value:', value)

            let isInvalid = false
            let ipPass = false

            if (value.destination && value.destination.ip.length > 0) {
                for (let i = 0; i < value.destination.ip.length; i++) {
                    if (!isValidIP(value.destination.ip[i])) {
                        isInvalid = true
                        break
                    }
                }
                if (!isInvalid) {
                    ipPass = true
                }
            }

            if (ipPass) {
                return isInvalid ? Promise.reject(new Error('请输入合法的IP或者选择IP组，请修改')) : Promise.resolve()
            }

            if (value.destination && value.destination.ip_set.length > 0) {
                isInvalid = false
            } else {
                isInvalid = true
            }

            return isInvalid ? Promise.reject(new Error('请输入合法的IP或者选择IP组，请修改')) : Promise.resolve()
        }
    }
}

// 校验所有标签格式
function isValidEnum() {
    return {
        trigger: 'change',
        required: true,
        validator(_: any, value: any) {
            let isInvalid = true
            if (typeof value !== 'undefined' && value !== '') {
                isInvalid = false
            }
            return isInvalid ? Promise.reject(new Error('请选择服务类型')) : Promise.resolve()
        }
    }
}

// 校验Registry格式
function isValidRegistryFormat() {
    return {
        trigger: 'change',
        required: true,
        validator(_: any, value: any) {
            const reg = /([\w.]*@tcp -h \d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3} -p \d{1,5} -t \d+)|((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}):(\d{1,5}))/img;
            let isInvalid = true
            if (reg.test(value)) {
                isInvalid = false
            }
            return isInvalid ? Promise.reject(new Error('请输入Registry地址，格式：{ip}:{port}，或者mfw.mfwregistry.QueryObj@tcp -h {host} -p {port} -t {timeout}')) : Promise.resolve()
        }
    }
}

// 校验屏蔽事件标签Key
function isValidShieldKey() {
    return {
        trigger: 'change',
        required: true,
        validator(_: any, value: any) {
            let isInvalid = true
            if (valueRegExp.test(value)) {
                isInvalid = false
            }
            return isInvalid ? Promise.reject(new Error('标签key必须是字母、数字、下划线，请修改')) : Promise.resolve()
        }
    }
}

function isJSONValid(str: any) {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

function readJSON(files: any, callback: any) {
    const file = files[0]
    if (!files) {
        return callback({
            code: 1,
            msg: '请选择上传的json文件！'
        })
    }
    if (file.type !== 'application/json') {
        return callback({
            code: 2,
            msg: '请选择json文件！'
        })
    }

    const reader = new FileReader() //新建一个FileReader
    reader.readAsText(files[0], 'UTF-8') //读取文件
    reader.onload = function (evt: any) {
        //读取完文件之后会回来这里
        const fileString = evt.target.result // 读取文件内容
        if (!isJSONValid(fileString)) {
            return callback({
                code: 3,
                msg: '上传的文件不是json格式！'
            })
        } else {
            return callback({
                code: 0,
                data: fileString
            })
        }
    }
}

function saveJSON(data: any, filename: any) {
    if (!filename) filename = 'json.json'
    if (typeof data === 'object') {
        data = JSON.stringify(data, undefined, 4)
    }
    const blob = new Blob([data], { type: 'text/json' }),
        e = document.createEvent('MouseEvents'),
        a = document.createElement('a')
    a.download = filename
    a.href = window.URL.createObjectURL(blob)
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
    e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    a.dispatchEvent(e)
}

// 校验所有标签格式
function isValidNumberAndGTR0() {
    return {
        trigger: 'change',
        required: true,
        validator(_: any, value: any) {
            let isInvalid = true
            if(/^\d+$/img.test(value) && parseInt(value, 10) > 0) {
                isInvalid = false
            }
            return isInvalid ? Promise.reject(new Error('必须是大于0的数字')) : Promise.resolve()
        }
    }
}

// 分页大小
const PAGE_SIZE = 20

export { getPromQLLabel, getSecurityGroupLists, getSecurityIPGroups, getSecurityPortRanges, getSecurityProtocols, isTagValid, isValidEnum, isValidFormat, isValidIPs, isValidIPSelect, isValidPort, isValidQL, isValidShieldKey, isValidSubject, isValidSubjectC, isValidTags, isValidTagsForShield, monitorGetRules, monitorGetRulesGroup, PAGE_SIZE, readJSON, saveJSON, isValidFile, isTagCommonValid, isValidIP, isValidRegistryFormat, isValidNumberAndGTR0 }
