import { getAppEnvConfig } from '@/utils/env'

// 项目id
export const APP_PROJECT_ID = getAppEnvConfig().projectId

// 项目小标题
export const APP_SUB_TITLE = 'ODIN权限系统'

// moa 接口
export const MOA_PATH = 'https://login.moa.moonton.net'

export const isDebug = true

export const KEEP_ALIVE_ARRAY = ['iamTemplate', 'iamGroup', 'iamToken', 'iamSuperuser']   
