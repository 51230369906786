import { PageEnum } from '@/enums/pageEnum'
import { t } from '@/hooks/web/useI18n'

import type { AppRouteRecordRaw } from '../types'
import { PAGE_NOT_FOUND_ROUTE, REDIRECT_ROUTE } from './basic'

const modules: Recordable<AppRouteRecordRaw> = import.meta.glob('./modules/**/*.ts', { eager: true, import: 'default' })

const routeModuleList: AppRouteRecordRaw[] = []

// 加入到路由集合中
Object.keys(modules).forEach((key) => {
    console.log('modules: ', modules);
    const mod = modules[key] || {}
    const modList = Array.isArray(mod) ? [...mod] : [mod]
    routeModuleList.push(...modList)
})

export const asyncRoutes = [PAGE_NOT_FOUND_ROUTE, ...routeModuleList]

export const RootRoute: AppRouteRecordRaw = {
    path: '/',
    name: 'Root',
    redirect: PageEnum.BASE_HOME,
    meta: {
        title: 'Root'
    }
}

export const LoginRoute: AppRouteRecordRaw = {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/sys/login/index.vue'),
    meta: {
        title: t('routes.basic.login')
    }
}

const basicRoutes = [LoginRoute, RootRoute, REDIRECT_ROUTE]

export default basicRoutes
