import 'virtual:windi.css'
import './styles/index.scss'
import './userWorker'

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import { createApp } from 'vue'

import App from './App.vue'
import { setupGlobDirectives } from './directives'
import setupI18n from './locales'
import { initAppConfigStore } from './logics/initAppConfig'
import setupRouter, { router } from './router'
import { setupRouterGuard } from './router/guard'
import setupStore from './store'

async function bootstrap() {
    const app = createApp(App)
    app.use(VueMonacoEditorPlugin, {
        paths: {
            // 推荐 CDN 配置
            vs: '/vs'
        }
    })

    setupStore(app)

    // 初始化内部系统配置
    initAppConfigStore()

    await setupI18n(app)

    // 路由配置
    setupRouter(app)

    // 路由守卫
    setupRouterGuard(router)

    // 注册全局指令
    setupGlobDirectives(app)

    app.mount('#app')
}

bootstrap()
