import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { clone, isEmpty, isNull, isString, isUndefined, mergeWith } from 'lodash-es'

import { useI18n } from '@/hooks/web/useI18n'
import { useMessage } from '@/hooks/web/useMessage'
import { useUserStoreWithOut } from '@/store/modules/user'
import { setObjToUrlParams } from '@/utils'
import { getEnvServer } from '@/utils/env'

import { MTAxios } from './Axios'
import { AxiosRetry } from './axiosRetry'
import type { AxiosTransform, CreateAxiosOptions } from './axiosTransform'
import { checkStatus } from './checkStatus'
import { ContentTypeEnum, RequestEnum, ResultEnum } from './enum'
import { formatRequestDate, joinTimestamp } from './helper'
import type { RequestOptions, Result } from './types'

const baseURL = getEnvServer()
const { createMessage, createModal } = useMessage()

/**
 * @description: 数据处理，方便区分多种处理方式
 */
const transform: AxiosTransform = {
    /**
     * @description: 处理响应数据。如果数据不是预期格式，可直接抛出错误
     */
    transformResponseHook: (res: AxiosResponse<Result>, options: RequestOptions) => {
        const { t } = useI18n()
        const { isTransformResponse, isReturnNativeResponse } = options
        // 是否返回原生响应头 比如：需要获取响应头时使用该属性
        if (isReturnNativeResponse) {
            return res
        }
        // 不进行任何处理，直接返回
        // 用于页面代码可能需要直接获取code，data，message这些信息时开启
        if (!isTransformResponse) {
            return res.data
        }
        // 错误的时候返回

        const { data } = res
        if (!data) {
            // return '[HTTP] Request has no return value';
            throw new Error(t('sys.api.apiRequestFailed'))
        }

        //  这里 code，result，message为 后台统一的字段，需要在 types.ts内修改为项目自己的接口返回格式
        const { code, result, message } = data

        // 这里逻辑可以根据项目进行修改
        const hasSuccess = data && Reflect.has(data, 'code') && code === ResultEnum.SUCCESS

        if (hasSuccess) {
            let successMsg = message

            if (isNull(successMsg) || isUndefined(successMsg) || isEmpty(successMsg)) {
                successMsg = t(`sys.api.operationSuccess`)
            }

            if (options.successMessageMode === 'modal') {
                createModal.success({ title: t('sys.api.successTip'), content: successMsg })
            } else if (options.successMessageMode === 'message') {
                createMessage.success(successMsg)
            }

            return result || data.data
        }

        let timeoutMsg = ''
        switch (code) {
            case ResultEnum.TOKEN_EXPIRED:
            case ResultEnum.TOKEN_INVALID:
            case ResultEnum.TOKEN_NOTFOUND:
            case ResultEnum.TOKEN_CODE: {
                timeoutMsg = t('sys.api.timeoutMessage')
                const userStore = useUserStoreWithOut()
                userStore.setToken(undefined)
                userStore.logout()
                break
            }
            default:
                if (message) {
                    timeoutMsg = message
                }
        }

        if (options.errorMessageMode === 'modal') {
            createModal.error({ title: t('sys.api.errorTip'), content: timeoutMsg })
        } else if (options.errorMessageMode === 'message') {
            createMessage.error(timeoutMsg)
        }

        throw new Error(timeoutMsg || t('sys.api.apiRequestFailed'))
    },

    // 请求之前处理config
    beforeRequestHook: (config, options) => {
        const { joinParamsToUrl, formatDate, joinTime = true } = options

        const params = config.params || {}
        const data = config.data || false
        formatDate && data && !isString(data) && formatRequestDate(data)
        if (config.method?.toUpperCase() === RequestEnum.GET) {
            if (!isString(params)) {
                // 给 get 请求加上时间戳参数，避免从缓存中拿数据。
                config.params = Object.assign(params || {}, joinTimestamp(joinTime, false))
            } else {
                // 兼容restful风格
                config.url = config.url + params + `${joinTimestamp(joinTime, true)}`
                config.params = undefined
            }
        } else {
            if (!isString(params)) {
                formatDate && formatRequestDate(params)
                if (Reflect.has(config, 'data') && config.data && (Object.keys(config.data).length > 0 || config.data instanceof FormData)) {
                    config.data = data
                    config.params = params
                } else {
                    // 非GET请求如果没有提供data，则将params视为data
                    config.data = params
                    config.params = undefined
                }
                if (joinParamsToUrl) {
                    config.url = setObjToUrlParams(config.url as string, Object.assign({}, config.params, config.data))
                }
            } else {
                // 兼容restful风格
                config.url = config.url + params
                config.params = undefined
            }
        }
        return config
    },

    /**
     * @description: 请求拦截器处理
     */
    requestInterceptors: (config, options) => {
        // 请求之前处理config
        const token = window.getToken()
        if (token && (config as Recordable)?.requestOptions?.withToken !== false) {
            // jwt token
            // ;(config.headers as Recordable).Authorization = options.authenticationScheme ? `${options.authenticationScheme} ${token}` : token

            // moa token
            ;(config.headers as Recordable)['X-MOA-Token'] = token
        }
        return config
    },

    /**
     * @description: 响应拦截器处理
     */
    responseInterceptors: (res: AxiosResponse<any>) => {
        return res
    },

    /**
     * @description: 响应错误处理
     */
    responseInterceptorsCatch: (axiosInstance: AxiosResponse, error: any) => {
        const { t } = useI18n()
        const { response, code, message, config } = error || {}
        const errorMessageMode = config?.requestOptions?.errorMessageMode || 'none'
        const msg: string = response?.data?.error?.message ?? ''
        const err: string = error?.toString?.() ?? ''
        let errMessage = ''

        if (axios.isCancel(error)) {
            return Promise.reject(error)
        }

        try {
            if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
                errMessage = t('sys.api.apiTimeoutMessage')
            }
            if (err?.includes('Network Error')) {
                errMessage = t('sys.api.networkExceptionMsg')
            }

            if (errMessage) {
                if (errorMessageMode === 'modal') {
                    createModal.error({ title: t('sys.api.errorTip'), content: errMessage })
                } else if (errorMessageMode === 'message') {
                    createMessage.error(errMessage)
                }
                return Promise.reject(error)
            }
        } catch (error) {
            throw new Error(error as unknown as string)
        }

        checkStatus(error?.response?.status, msg, errorMessageMode)

        // 添加自动重试机制 保险起见 只针对GET请求
        const retryRequest = new AxiosRetry()
        const { isOpenRetry } = config.requestOptions.retryRequest
        config.method?.toUpperCase() === RequestEnum.GET &&
            isOpenRetry &&
            // @ts-ignore
            retryRequest.retry(axiosInstance, error)
        return Promise.reject(error)
    }
}

function createAxios(opt?: Partial<CreateAxiosOptions>) {
    return new MTAxios(
        // 深度合并
        mergeWith(
            {
                // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication#authentication_schemes
                // authentication schemes，e.g: Bearer
                // authenticationScheme: 'Bearer',
                authenticationScheme: '',
                timeout: 30 * 1000,
                // 基础接口地址
                baseURL: baseURL,

                // 允许携带cookie
                withCredentials: true,

                headers: { 'Content-Type': ContentTypeEnum.JSON },
                // 如果是form-data格式
                // headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED },
                // 数据处理方式
                transform: clone(transform),
                // 配置项，下面的选项都可以在独立的接口请求中覆盖
                requestOptions: {
                    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
                    isReturnNativeResponse: false,
                    // 需要对返回数据进行处理
                    isTransformResponse: true,
                    // post请求的时候添加参数到url
                    joinParamsToUrl: false,
                    // 格式化提交参数时间
                    formatDate: true,
                    // 消息提示类型
                    errorMessageMode: 'message',
                    // 是否加入时间戳
                    joinTime: true,
                    // 忽略重复请求
                    ignoreCancelToken: true,
                    // 是否携带token
                    withToken: true,
                    // 轮询--暂时只对get请求有效
                    retryRequest: {
                        isOpenRetry: false,
                        count: 5,
                        waitTime: 100
                    }
                }
            },
            opt || {}
        )
    )
}

const defHttp = createAxios()

export default defHttp
