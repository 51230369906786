import nProgress from 'nprogress'
import { unref } from 'vue'
import { RouteLocationNormalized, Router } from 'vue-router'

import projectSetting from '@/config/project'
import { useTransitionSetting } from '@/hooks/setting/useTransitionSetting'
import { setRouteChange } from '@/logics/mitt/routeChange'
import { useAppStoreWithOut } from '@/store/modules/app'
import { useUserStoreWithOut } from '@/store/modules/user'
import { AxiosCanceler } from '@/utils/http/axiosCancel'

import { createParamMenuGuard } from './paramMenuGuard'
import { createPermissionGuard } from './permissionGuard'
import { createStateGuard } from './stateGuard'

// 处理页面状态的钩子
function createPageGuard(router: Router) {
    const loadedPageMap = new Map<string, boolean>()

    router.beforeEach(async (to) => {
        to.meta.loaded = !!loadedPageMap.get(to.path)

        setRouteChange(to)

        return true
    })

    router.afterEach((to) => {
        loadedPageMap.set(to.path, true)
    })
}

//用于处理页面加载状态
function createPageLoadingGuard(router: Router) {
    const userStore = useUserStoreWithOut()
    const appStore = useAppStoreWithOut()
    const { getOpenPageLoading } = useTransitionSetting()
    router.beforeEach(async (to) => {
        if (!userStore.getToken) {
            return true
        }
        if (to.meta.loaded) {
            return true
        }

        if (unref(getOpenPageLoading)) {
            appStore.setPageLoadingAction(true)
            return true
        }

        return true
    })

    router.afterEach(async () => {
        if (unref(getOpenPageLoading)) {
            // 计时器模拟加载时间，以防止闪烁太快;
            setTimeout(() => {
                appStore.setPageLoading(false)
            }, 220)
        }
        return true
    })
}

// 切换页面、取消当前页面请求
function createHttpGuard(router: Router) {
    const { removeAllHttpPending } = projectSetting
    let axiosCanceler: Nullable<AxiosCanceler>
    if (removeAllHttpPending) {
        axiosCanceler = new AxiosCanceler()
    }
    router.beforeEach(async () => {
        axiosCanceler?.removeAllPending()
        return true
    })
}

// 路由切换时，滚动到顶部
function createScrollGuard(router: Router) {
    const isHash = (href: string) => {
        return /^#/.test(href)
    }

    const body = document.body

    router.afterEach(async (to) => {
        // scroll top
        isHash((to as RouteLocationNormalized & { href: string })?.href) && body.scrollTo(0, 0)
        return true
    })
}

// 路由切换时关闭消息提示
export function createMessageGuard(router: Router) {
    const { closeMessageOnSwitch } = projectSetting

    router.beforeEach(async () => {
        if (closeMessageOnSwitch) {
            // todo  更具 useMessage 的实现来关闭消息提示
        }

        return true
    })
}

// 页面加载进度条
export function createProgressGuard(router: Router) {
    const { getOpenNProgress } = useTransitionSetting()

    router.beforeEach(async (to) => {
        if (to.meta.loaded) {
            return true
        }
        unref(getOpenNProgress) && nProgress.start()
        return true
    })

    router.afterEach(async () => {
        unref(getOpenNProgress) && nProgress.done()
        return true
    })
}

export function setupRouterGuard(router: Router) {
    createPageGuard(router)
    createPageLoadingGuard(router)
    createHttpGuard(router)
    createScrollGuard(router)
    createMessageGuard(router)
    createProgressGuard(router)
    createPermissionGuard(router)
    createParamMenuGuard(router)
    createStateGuard(router)
}
