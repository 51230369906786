import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'

import basicRoutes from './routes'

const WHITE_NAME_LIST: string[] = []
const getRouteNames = (array: any[]) =>
    array.forEach((item) => {
        WHITE_NAME_LIST.push(item.name)
        getRouteNames(item.children || [])
    })
getRouteNames(basicRoutes)

export const router = createRouter({
    history: createWebHashHistory(import.meta.env.VITE_PUBLIC_PATH),
    routes: basicRoutes as unknown as RouteRecordRaw[],
    strict: true,
    scrollBehavior: () => ({ left: 0, top: 0 })
})

export function resetRouter() {
    router.getRoutes().forEach((route) => {
        const { name } = route
        if (name && !WHITE_NAME_LIST.includes(name as string)) {
            router.hasRoute(name) && router.removeRoute(name)
        }
    })
}

function setupRouter(app: App) {
    app.use(router)
}

export default setupRouter
