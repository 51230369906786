import type { InjectionKey, UnwrapRef } from 'vue'
import { inject, provide, reactive, readonly as defineReadonly } from 'vue'

export interface CreateContextOptions {
    readonly?: boolean
    native?: boolean
}

type ShallowUnwrap<T> = {
    [P in keyof T]: UnwrapRef<T[P]>
}

export function createContext<T>(context: T, key: InjectionKey<T> = Symbol(), options: CreateContextOptions = {}) {
    const { readonly, native } = options

    const state = reactive(context as any)
    const provideData = readonly ? defineReadonly(state) : state

    provide(key, native ? context : provideData)

    return { state }
}

export function useContext<T>(key: InjectionKey<T>, native?: boolean): T

export function useContext<T>(key: InjectionKey<T> = Symbol(), defaultValue?: any): ShallowUnwrap<T> {
    return inject(key, defaultValue || {})
}
