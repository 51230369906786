import { PageEnum } from '@/enums/pageEnum'
import { useI18n } from '@/hooks/web/useI18n'
import { useMessage } from '@/hooks/web/useMessage'
import { useGo } from '@/hooks/web/usePage'
import { router } from '@/router'
import { useUserStoreWithOut } from '@/store/modules/user'

import type { ErrorMessageMode } from './types'

const { createMessage, createModal } = useMessage()

export function checkStatus(status: number, msg: string, errorMessageMode: ErrorMessageMode = 'message'): void {
    const { t } = useI18n()
    const userStore = useUserStoreWithOut()
    let errMessage = msg

    switch (status) {
        case 400:
            errMessage = msg
            break
        case 401:
            // 处理权限问题
            errMessage = msg || t('sys.api.errMsg401')
            userStore.logout()
            break
        case 403: {
            errMessage = t('sys.api.errMsg403')
            const go = useGo(router)
            go({ path: PageEnum.ERROR_PAGE, query: { status: 403 } }, true)
            break
        }
        // 404请求不存在
        case 404:
            errMessage = t('sys.api.errMsg404')
            break
        case 405:
            errMessage = t('sys.api.errMsg405')
            break
        case 408:
            errMessage = t('sys.api.errMsg408')
            break
        case 500:
            errMessage = t('sys.api.errMsg500')
            break
        case 501:
            errMessage = t('sys.api.errMsg501')
            break
        case 502:
            errMessage = t('sys.api.errMsg502')
            break
        case 503:
            errMessage = t('sys.api.errMsg503')
            break
        case 504:
            errMessage = t('sys.api.errMsg504')
            break
        case 505:
            errMessage = t('sys.api.errMsg505')
            break
        default:
    }

    if (errMessage) {
        if (errorMessageMode === 'modal') {
            createModal.error({ title: t('sys.api.errorTip'), content: errMessage })
        } else if (errorMessageMode === 'message') {
            createMessage.error(errMessage)
        }
    }
}
