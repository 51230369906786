import pkg from '../../package.json'

export function getAppEnvConfig() {
    const ENV = import.meta.env

    const { VITE_GLOB_APP_SHORT_NAME, VITE_GLOB_APP_CACHE_PREFIX, VITE_APP_PROJECT_ID, VITE_APP_DOMAIN, VITE_APP_BASE_URL } = ENV

    return {
        globalAppShortName: VITE_GLOB_APP_SHORT_NAME,
        prefixKey: VITE_GLOB_APP_CACHE_PREFIX || 'MOONTON_ADMIN',
        projectId: +VITE_APP_PROJECT_ID || 234,
        domain: VITE_APP_DOMAIN || window.location.hostname,
        baseUrl: VITE_APP_BASE_URL
    }
}

export function getCommonStoragePrefix() {
    const { globalAppShortName } = getAppEnvConfig()

    return `${globalAppShortName}__${getEnv()}`.toUpperCase()
}

export function getStorageShortName() {
    const { prefixKey } = getAppEnvConfig()
    return `${prefixKey}${`__${pkg.version}`}__`.toUpperCase()
}

export function getEnvServer() {
    return getAppEnvConfig().baseUrl
}

export function isDevMode(): boolean {
    return import.meta.env.DEV
}

export function isProdMode(): boolean {
    return import.meta.env.PROD
}

export function getEnv(): string {
    return import.meta.env.MODE
}
