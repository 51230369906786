const docEle = document.documentElement
export function toggleClass(flag: boolean, clsName: string, target?: HTMLElement) {
    const targetEl = target || document.body
    let { className } = targetEl
    className = className.replace(clsName, '')
    targetEl.className = flag ? `${className} ${clsName} ` : className
}

export function setCssVar(prop: string, val: any, dom = docEle) {
    dom.style.setProperty(prop, val)
}

/* istanbul ignore next */
export function on(element: Element | HTMLElement | Document | Window, event: string, handler: EventListenerOrEventListenerObject): void {
    if (element && event && handler) {
        element.addEventListener(event, handler, false)
    }
}

/* istanbul ignore next */
export function off(element: Element | HTMLElement | Document | Window, event: string, handler: Fn): void {
    if (element && event && handler) {
        element.removeEventListener(event, handler, false)
    }
}
