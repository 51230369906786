import { defineStore } from 'pinia'

import { APP_DARK_MODE_KEY_, PROJ_CFG_KEY } from '@/config/cache'
import { darkMode } from '@/config/design'
import { ThemeEnum } from '@/enums/appEnum'
import { resetRouter } from '@/router'
import { BeforeMiniState } from '@/types/store'
import { deepMerge } from '@/utils'
import { Persistent } from '@/utils/cache/persistent'
import { HeaderSetting, MenuSetting, MultiTabsSetting, ProjectConfig, TransitionSetting } from '#/config'

import { store } from '../index'

interface AppState {
    darkMode: ThemeEnum
    pageLoading: boolean
    projectConfig: ProjectConfig | null
    beforeMiniInfo: BeforeMiniState // 窗口最小化前的状态
}

let timeId: TimeoutHandle

const useAppStore = defineStore({
    id: 'app',
    state: (): AppState => ({
        darkMode,
        pageLoading: false,
        projectConfig: Persistent.getLocal(PROJ_CFG_KEY),
        beforeMiniInfo: {}
    }),
    getters: {
        getPageLoading(): boolean {
            return this.pageLoading
        },

        getDarkMode(): ThemeEnum {
            return this.darkMode || localStorage.getItem(APP_DARK_MODE_KEY_) || darkMode
        },

        getBeforeMiniInfo(): BeforeMiniState {
            return this.beforeMiniInfo
        },

        getProjectConfig(): ProjectConfig {
            return this.projectConfig || ({} as ProjectConfig)
        },

        getHeaderSetting(): HeaderSetting {
            return this.getProjectConfig.headerSetting
        },

        getMenuSetting(): MenuSetting {
            return this.getProjectConfig.menuSetting
        },

        getTransitionSetting(): TransitionSetting {
            return this.getProjectConfig.transitionSetting
        },

        getMultiTabsSetting(): MultiTabsSetting {
            return this.getProjectConfig.multiTabsSetting
        }
    },
    actions: {
        setPageLoading(loading: boolean): void {
            this.pageLoading = loading
        },

        setDarkMode(mode: ThemeEnum): void {
            this.darkMode = mode
            localStorage.setItem(APP_DARK_MODE_KEY_, mode)
        },

        setBeforeMiniInfo(state: BeforeMiniState): void {
            this.beforeMiniInfo = state
        },

        setProjectConfig(config: DeepPartial<ProjectConfig>): void {
            this.projectConfig = deepMerge(this.projectConfig || {}, config)
            Persistent.setLocal(PROJ_CFG_KEY, this.projectConfig)
        },

        async resetAllState() {
            resetRouter()
            Persistent.clearAll()
        },

        async setPageLoadingAction(loading: boolean): Promise<void> {
            if (loading) {
                clearTimeout(timeId)
                timeId = setTimeout(() => {
                    this.setPageLoading(loading)
                }, 50)
            } else {
                this.setPageLoading(loading)
                clearTimeout(timeId)
            }
        }
    }
})

export function useAppStoreWithOut() {
    return useAppStore(store)
}

export default useAppStore
