import { defineStore } from 'pinia'
import { toRaw } from 'vue'

import projectSetting from '@/config/project'
import { PermissionModeEnum } from '@/enums/appEnum'
import { PageEnum } from '@/enums/pageEnum'
import { RoleEnum } from '@/enums/roleEnum'
import { useI18n } from '@/hooks/web/useI18n'
import { useMessage } from '@/hooks/web/useMessage'
import { transformRouteToMenu } from '@/router/helper/menuHelper'
import { flatMultiLevelRoutes, transformObjToRoute } from '@/router/helper/routeHelper'
import { asyncRoutes } from '@/router/routes'
import { PAGE_NOT_FOUND_ROUTE } from '@/router/routes/basic'
import { AppRouteRecordRaw, Menu } from '@/router/types'
import { filter } from '@/utils/tree'

import { store } from '../index'
import { useAppStoreWithOut } from './app'
import { useUserStore } from './user'

interface PermissionState {
    // 权限代码列表
    permCodeList: string[] | number[]
    // 路由是否动态添加
    isDynamicAddedRoute: boolean
    // 触发菜单更新
    lastBuildMenuTime: number
    // 后台菜单列表
    backMenuList: Menu[]
    // 菜单列表
    frontMenuList: Menu[]
}

export const usePermissionStore = defineStore({
    id: 'app-permission',
    state: (): PermissionState => ({
        // 权限代码列表
        permCodeList: [],
        // 路由是否动态添加
        isDynamicAddedRoute: false,
        // 触发菜单更新
        lastBuildMenuTime: 0,
        // 后台菜单列表
        backMenuList: [],
        // 菜单列表
        frontMenuList: []
    }),
    getters: {
        getPermCodeList(): string[] | number[] {
            return this.permCodeList
        },
        getBackMenuList(): Menu[] {
            return this.backMenuList
        },
        getFrontMenuList(): Menu[] {
            return this.frontMenuList
        },
        getLastBuildMenuTime(): number {
            return this.lastBuildMenuTime
        },
        getIsDynamicAddedRoute(): boolean {
            return this.isDynamicAddedRoute
        }
    },
    actions: {
        setPermCodeList(codeList: string[]) {
            this.permCodeList = codeList
        },

        setBackMenuList(list: Menu[]) {
            this.backMenuList = list
            list?.length > 0 && this.setLastBuildMenuTime()
        },

        setFrontMenuList(list: Menu[]) {
            this.frontMenuList = list
        },

        setLastBuildMenuTime() {
            this.lastBuildMenuTime = new Date().getTime()
        },

        setDynamicAddedRoute(added: boolean) {
            this.isDynamicAddedRoute = added
        },
        resetState(): void {
            this.isDynamicAddedRoute = false
            this.permCodeList = []
            this.backMenuList = []
            this.lastBuildMenuTime = 0
        },
        async changePermissionCode() {
            const codeList: Array<string> = [] // 这里接口获取权限、然后更新权限
            this.setPermCodeList(codeList)
        },

        // 构建路由
        async buildRoutesAction(): Promise<AppRouteRecordRaw[]> {
            const { t } = useI18n()
            const userStore = useUserStore()
            const appStore = useAppStoreWithOut()

            let routes: AppRouteRecordRaw[] = []
            const roleList = toRaw(userStore.getRoleList) || []
            const { permissionMode = projectSetting.permissionMode } = appStore.getProjectConfig

            // 路由过滤器 在 函数filter 作为回调传入遍历使用
            const routeFilter = (route: AppRouteRecordRaw) => {
                const { meta } = route
                // 抽出角色
                const { roles } = meta || {}
                if (!roles) return true
                // 进行角色权限判断
                return roleList.some((role) => (roles as Array<RoleEnum>).includes(role))
            }

            const routeRemoveIgnoreFilter = (route: AppRouteRecordRaw) => {
                const { meta } = route
                // ignoreRoute 为true 则路由仅用于菜单生成，不会在实际的路由表中出现
                const { ignoreRoute } = meta || {}
                // arr.filter 返回 true 表示该元素通过测试
                return !ignoreRoute
            }

            /**
             * @description 根据设置的首页path，修正routes中的affix标记（固定首页）
             * */
            const patchHomeAffix = (routes: AppRouteRecordRaw[]) => {
                if (!routes || routes.length === 0) return
                let homePath: string = userStore.getUserInfo.homePath || PageEnum.BASE_HOME

                function patcher(routes: AppRouteRecordRaw[], parentPath = '') {
                    if (parentPath) parentPath = parentPath + '/'
                    routes.forEach((route: AppRouteRecordRaw) => {
                        const { path, children, redirect } = route
                        const currentPath = path.startsWith('/') ? path : parentPath + path
                        if (currentPath === homePath) {
                            if (redirect) {
                                homePath = route.redirect! as string
                            } else {
                                route.meta = Object.assign({}, route.meta, { affix: true })
                                throw new Error('end')
                            }
                        }
                        children && children.length > 0 && patcher(children, currentPath)
                    })
                }

                try {
                    patcher(routes)
                } catch (e) {
                    // 已处理完毕跳出循环
                }
                return
            }

            switch (permissionMode) {
                // 角色权限
                case PermissionModeEnum.ROLE:
                    // 对非一级路由进行过滤
                    routes = filter(asyncRoutes, routeFilter)
                    // 对一级路由根据角色权限过滤
                    routes = routes.filter(routeFilter)
                    // 将多级路由转换为 2 级路由
                    routes = flatMultiLevelRoutes(routes)
                    break

                // 路由映射， 默认进入该case
                case PermissionModeEnum.ROUTE_MAPPING: {
                    // 对非一级路由进行过滤
                    routes = filter(asyncRoutes, routeFilter)
                    // 对一级路由再次根据角色权限过滤
                    routes = routes.filter(routeFilter)
                    // 将路由转换成菜单
                    const menuList = transformRouteToMenu(routes, true)
                    // 移除掉 ignoreRoute: true 的路由 非一级路由
                    routes = filter(routes, routeRemoveIgnoreFilter)
                    // 移除掉 ignoreRoute: true 的路由 一级路由；
                    routes = routes.filter(routeRemoveIgnoreFilter)
                    // 对菜单进行排序
                    menuList.sort((a, b) => {
                        const aOrderNo = (a.meta?.orderNo || 0) as number
                        const bOrderNo = (b.meta?.orderNo || 0) as number
                        return aOrderNo - bOrderNo
                    })

                    // 设置菜单列表
                    this.setFrontMenuList(menuList)

                    // 将多级路由转换为 2 级路由
                    routes = flatMultiLevelRoutes(routes)
                    break
                }

                //  如果确定不需要做后台动态权限，请在下方注释整个判断
                case PermissionModeEnum.BACK: {
                    const { createMessage } = useMessage()

                    createMessage.loading(t('sys.app.menuLoading'))

                    // 这个功能可能只需要执行一次，实际项目可以自己放在合适的时间
                    let routeList: AppRouteRecordRaw[] = []
                    try {
                        // 这里动态获取后台路由菜单，实际项目可以不需要传递参数
                        await this.changePermissionCode()
                        routeList = [] as AppRouteRecordRaw[]
                    } catch (error) {
                        console.error(error)
                    }

                    // 动态引入组件
                    routeList = transformObjToRoute(routeList)

                    //  后台路由到菜单结构
                    const backMenuList = transformRouteToMenu(routeList)
                    this.setBackMenuList(backMenuList)

                    // 删除 meta.ignoreRoute 项
                    routeList = filter(routeList, routeRemoveIgnoreFilter)
                    routeList = routeList.filter(routeRemoveIgnoreFilter)

                    routeList = flatMultiLevelRoutes(routeList)
                    routes = [PAGE_NOT_FOUND_ROUTE, ...routeList]
                    break
                }
            }

            patchHomeAffix(routes)
            return routes
        }
    }
})

export function usePermissionStoreWithOut() {
    return usePermissionStore(store)
}
