import Cookies from 'js-cookie'
import { isPlainObject } from 'lodash-es'

import { cacheCipher } from '@/config/encryption'

import type { EncryptionParams } from '../cipher'
import { AesEncryption } from '../cipher'

export interface CreateCookieParams extends EncryptionParams {
    prefixKey: string
    hasEncrypt: boolean
    options?: Cookies.CookieAttributes
}

export const createCookie = ({ prefixKey = '', key = cacheCipher.key, iv = cacheCipher.iv, hasEncrypt = true, options }: Partial<CreateCookieParams> = {}) => {
    if (hasEncrypt && [key.length, iv.length].some((item) => item !== 16)) {
        throw new Error('When hasEncrypt is true, the key or iv must be 16 bits!')
    }

    const encryption = new AesEncryption({ key, iv })

    const WebCookie = class WebCookie {
        private cookies: typeof Cookies
        private prefixKey?: string
        private encryption: AesEncryption
        private hasEncrypt: boolean
        private options?: Cookies.CookieAttributes

        constructor() {
            this.cookies = Cookies
            this.prefixKey = prefixKey
            this.encryption = encryption
            this.hasEncrypt = hasEncrypt
            this.options = options
        }

        private getKey(key: string) {
            // return `${this.prefixKey}${key}`.toUpperCase()
            return `${this.prefixKey}${key}`
        }

        /**
         * 设置缓存
         * @param {string} key
         * @param {*} value
         * @param {*} options cookie attributes
         * @memberof Cache
         */
        set(key: string, value: any, options?: Cookies.CookieAttributes) {
            // const stringData = JSON.stringify({ value })
            let stringData: any
            try {
                stringData = isPlainObject(value) ? JSON.stringify(value) : value
            } catch (e) {
                stringData = value
            }
            const stringifyValue = this.hasEncrypt ? this.encryption.encryptByAES(stringData) : stringData
            this.cookies.set(this.getKey(key), stringifyValue, { ...this.options, ...options })
        }

        /**
         * 读取缓存
         * @param {string} key
         * @param {*} def
         * @memberof Cache
         */
        get(key: string, def: any = null): any {
            const val = this.cookies.get(this.getKey(key))
            if (!val) return def

            try {
                const decVal = this.hasEncrypt ? this.encryption.decryptByAES(val) : val
                try {
                    return JSON.parse(decVal)
                } catch (e) {
                    return decVal
                }
            } catch (e) {
                return def
            }
        }

        /**
         * 删除指定key的缓存
         * @param {string} key
         * @memberof Cache
         */
        remove(key: string) {
            this.cookies.remove(this.getKey(key), this.options)
        }
    }

    return new WebCookie()
}
