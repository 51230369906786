import { PROJ_CFG_KEY } from '@/config/cache'
import projectSetting from '@/config/project'
import { ThemeEnum } from '@/enums/appEnum'
import { updateGrayMode } from '@/hooks/web/updateGrayMode'
import useAppStore from '@/store/modules/app'
import useLocaleStore from '@/store/modules/locale'
import { ProjectConfig } from '@/types/config'
import { deepMerge } from '@/utils'
import { Persistent } from '@/utils/cache/persistent'
import { getCommonStoragePrefix, getStorageShortName } from '@/utils/env'

// 删除沉余的存储
export function clearObsoleteStorage() {
    const commonPrefix = getCommonStoragePrefix()
    const shortPrefix = getStorageShortName()

    ;[localStorage, sessionStorage].forEach((item: Storage) => {
        Object.keys(item).forEach((key) => {
            if (key && key.startsWith(commonPrefix) && !key.startsWith(shortPrefix)) {
                item.removeItem(key)
            }
        })
    })
}

export function initAppConfigStore() {
    const localeStore = useLocaleStore()
    const appStore = useAppStore()
    let projCfg: ProjectConfig = Persistent.getLocal(PROJ_CFG_KEY) as ProjectConfig
    projCfg = deepMerge(projectSetting, projCfg || {})
    const { grayMode } = projCfg

    // 切换主体颜色
    try {
        grayMode && updateGrayMode(grayMode)
    } catch (error) {
        console.log(error)
    }
    appStore.setProjectConfig(projCfg)

    // init dark mode
    // updateDarkTheme(darkMode)
    // if (darkMode === ThemeEnum.DARK) {
    //     updateHeaderBgColor()
    //     updateSidebarBgColor()
    // } else {
    //     headerBgColor && updateHeaderBgColor(headerBgColor)
    //     bgColor && updateSidebarBgColor(bgColor)
    // }

    localeStore.initLocale()

    setTimeout(() => {
        clearObsoleteStorage()
    }, 16)
}
