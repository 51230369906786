interface TreeHelperConfig {
    id: string
    children: string
    pid: string
}

// 默认配置
const DEFAULT_CONFIG: TreeHelperConfig = {
    id: 'id',
    children: 'children',
    pid: 'pid'
}

// 获取配置。  Object.assign 从一个或多个源对象复制到目标对象
const getConfig = (config: Partial<TreeHelperConfig>) => Object.assign({}, DEFAULT_CONFIG, config)

export function filter<T = any>(
    tree: T[],
    func: (n: T) => boolean,
    // Partial 将 T 中的所有属性设为可选
    config: Partial<TreeHelperConfig> = {}
): T[] {
    // 获取配置
    config = getConfig(config)
    const children = config.children as string

    function listFilter(list: T[]) {
        return list
            .map((node: any) => ({ ...node }))
            .filter((node) => {
                // 递归调用 对含有children项  进行再次调用自身函数 listFilter
                node[children] = node[children] && listFilter(node[children])
                // 执行传入的回调 func 进行过滤
                return func(node) || (node[children] && node[children].length)
            })
    }

    return listFilter(tree)
}

/**
 * @description: Extract tree specified structure
 * @description: 提取树指定结构
 */
export function treeMapEach(data: any, { children = 'children', conversion }: { children?: string; conversion: Fn }) {
    const haveChildren = Array.isArray(data[children]) && data[children].length > 0
    const conversionData = conversion(data) || {}
    if (haveChildren) {
        return {
            ...conversionData,
            [children]: data[children].map((i: number) =>
                treeMapEach(i, {
                    children,
                    conversion
                })
            )
        }
    } else {
        return {
            ...conversionData
        }
    }
}

/**
 * @description: Extract tree specified structure
 * @description: 提取树指定结构
 */
export function treeMap<T = any>(treeData: T[], opt: { children?: string; conversion: Fn }): T[] {
    return treeData.map((item) => treeMapEach(item, opt))
}

export function findPath<T = any>(tree: any, func: Fn, config: Partial<TreeHelperConfig> = {}): T | T[] | null {
    config = getConfig(config)
    const path: T[] = []
    const list = [...tree]
    const visitedSet = new Set()
    const { children } = config
    while (list.length) {
        const node = list[0]
        if (visitedSet.has(node)) {
            path.pop()
            list.shift()
        } else {
            visitedSet.add(node)
            node[children!] && list.unshift(...node[children!])
            path.push(node)
            if (func(node)) {
                return path
            }
        }
    }
    return null
}
