export enum ResultEnum {
    SUCCESS = 0,
    ERROR = -1,
    TOKEN_EXPIRED = 401,
    TOKEN_CODE = 104, // moa token 过期
    TOKEN_INVALID = 105, // moa token 无效
    TOKEN_NOTFOUND = 100208 // moa token 不存在
}

export enum RequestEnum {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export enum ContentTypeEnum {
    // json
    JSON = 'application/json;charset=UTF-8',
    // form-data qs
    FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  upload
    FORM_DATA = 'multipart/form-data;charset=UTF-8'
}
