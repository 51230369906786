import { DAY_NUMBER, DEFAULT_CACHE_TIME, enableStorageEncryption } from '@/config/encryption'
import { getAppEnvConfig, getStorageShortName } from '@/utils/env'

import { createCookie, CreateCookieParams } from './cookieCache'
import { createStorage as create, CreateStorageParams } from './storageCache'

export type Options = Partial<CreateStorageParams>

const createOptions = (storage: Storage, options: Options = {}): Options => {
    return {
        // 调试模式下不加密
        hasEncrypt: enableStorageEncryption,
        storage,
        prefixKey: getStorageShortName(),
        ...options
    }
}

const createCookieOptions = (options: Partial<CreateCookieParams> = {}): Options => {
    return {
        hasEncrypt: false,
        // prefixKey: getStorageShortName(),
        options: { expires: DAY_NUMBER, domain: getAppEnvConfig().domain },
        ...options
    }
}

export const webCookie = createCookie(createCookieOptions())

export const webStorage = create(createOptions(sessionStorage))

export const createStorage = (storage: Storage = sessionStorage, options: Options = {}) => {
    return create(createOptions(storage, options))
}

export const createSessionStorage = (options: Options = {}) => {
    return createStorage(sessionStorage, { ...options, timeout: DEFAULT_CACHE_TIME })
}

export const createLocalStorage = (options: Options = {}) => {
    return createStorage(localStorage, { ...options, timeout: DEFAULT_CACHE_TIME })
}

export default webStorage
