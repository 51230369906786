import { unref } from 'vue'
import { RouteLocationRaw, Router, useRouter } from 'vue-router'

import { PageEnum } from '@/enums/pageEnum'
import { REDIRECT_NAME } from '@/router/constant'
import webStorage from '@/utils/cache'

export type PathAsPageEnum<T> = T extends { path: string } ? T & { path: PageEnum } : T
export type RouteLocationRawEx = PathAsPageEnum<RouteLocationRaw>

function handleError(e: Error) {
    console.error(e)
}

// 页面切换
export function useGo(_router?: Router) {
    const { push, replace } = _router || useRouter()
    function go(opt: RouteLocationRawEx = PageEnum.BASE_HOME, isReplace = false) {
        if (!opt) {
            return
        }
        isReplace ? replace(opt).catch(handleError) : push(opt).catch(handleError)
    }
    return go
}

// 页面重载
export const useRedo = (_router?: Router) => {
    const { replace, currentRoute } = _router || useRouter()
    const { query, params = {}, name, fullPath } = unref(currentRoute.value)
    function redo(): Promise<boolean> {
        return new Promise((resolve) => {
            if (name === REDIRECT_NAME) {
                resolve(false)
                return
            }

            if (name && Object.keys(params).length > 0) {
                webStorage.set('_redirect_type_', 'name')
                params['path'] = String(name)
            } else {
                webStorage.set('_redirect_type_', 'path')
                params['path'] = fullPath
            }
            console.log(params, query, '====ppp')
            replace({ name: REDIRECT_NAME, params, query }).then(() => resolve(true))
        })
    }
    return redo
}
