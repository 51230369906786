<template>
    <ConfigProvider :locale="getAntdLocale" :theme="theme">
        <AppProvider>
            <RouterView />
        </AppProvider>
    </ConfigProvider>
</template>

<script setup lang="ts">
import 'dayjs/locale/zh-cn'

import { ConfigProvider } from 'ant-design-vue'
import { RouterView } from 'vue-router'

import AppProvider from '@/components/Application'
import useTitle from '@/hooks/web/useTitle'
import { useLocale } from '@/locales/useLocale'
import { theme } from '@/synthesize/theme.ts'

const { getAntdLocale } = useLocale()

useTitle()
</script>
