import { MOA_PATH } from '@/config'
import defHttp from '@/utils/http'

export type RoleInfo = Array<string>

interface GetUserInfoModel {
    uid: number // 用户id
    nick: string // 用户名
    name: string // 真实名字
    token: string // token
    email: string // 邮箱
    mobile: string // 手机号
}

enum Api {
    GetUserInfo = '/api/refresh',
    GetRoles = '/api/getperms'
}

const generateApi = (type: keyof typeof Api) => `${MOA_PATH}${Api[type]}`

/**
 * @description: getUserInfo
 */
export function getUserInfo(token: string) {
    return defHttp.post<GetUserInfoModel>({ url: generateApi('GetUserInfo'), data: { token } }, { errorMessageMode: 'none' })
}

/**
 * @description: getRoles
 */
export function getRoles(token: string) {
    return defHttp.post<RoleInfo>({ url: generateApi('GetRoles'), data: { token } }, { errorMessageMode: 'none' })
}

export async function listUser() {
    return defHttp.get({
      url: 'iam/v1/users'
    })
}