// 用户 token
export const TOKEN_KEY = 'TOKEN'

// 用户token 存在于cookie中的key、用于一级域名相同的站点数据共享
export const MOA_AUTH_INFO = 'MOA_AUTH_INFO'

// 当前平台
export const PLATFORM_KEY = 'PLATFORM'

// 本地化信息
export const LOCALE_KEY = 'LOCALE'

// 用户信息
export const USER_INFO_KEY = 'USER__INFO'

// 登录相关信息
export const LOCK_INFO_KEY = 'LOCK__INFO'

// 权限
export const ROLES_KEY = 'ROLES'

// 项目配置
export const PROJ_CFG_KEY = 'PROJ__CFG'

// 锁定信息-路由配置
export const MULTIPLE_TABS_KEY = 'MULTIPLE_TABS'

// 全局本地缓存 key
export const APP_LOCAL_CACHE_KEY = 'COMMON__LOCAL'

// 全局会话缓存 key
export const APP_SESSION_CACHE_KEY = 'COMMON__SESSION'

// 当前主题缓存 key
export const APP_DARK_MODE_KEY_ = '__APP__DARK__MODE__'

export enum CacheTypeEnum {
    SESSION,
    LOCAL
}
