// 菜单类型
export enum MenuTypeEnum {
    SIDEBAR = 'sidebar', // 左侧菜单
    TOP_MENU = 'top-menu' // 顶部菜单
}

// 折叠触发器位置
export enum TriggerEnum {
    // 不显示
    NONE = 'NONE',
    // 菜单底部
    FOOTER = 'FOOTER',
    // 头部
    HEADER = 'HEADER'
}

export type Mode = 'vertical' | 'horizontal' | 'inline'

// 菜单模式
export enum MenuModeEnum {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
    INLINE = 'inline'
}

export enum TopMenuAlignEnum {
    CENTER = 'center',
    START = 'start',
    END = 'end'
}
