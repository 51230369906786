<template><slot /></template>

<script setup lang="tsx">
import { ref, toRefs, unref } from 'vue'

import { prefixCls as defaultPrefixCls } from '@/config/design'
import { MenuModeEnum, MenuTypeEnum } from '@/enums/menuEnum'
import { createBreakpointListen } from '@/hooks/event/useBreakpoint'
import useAppStore from '@/store/modules/app'

import { createAppProviderContext } from './useAppContext'

defineOptions({
    inheritAttrs: false
})

interface AppProviderProps {
    prefixCls?: string
}

const props = withDefaults(defineProps<AppProviderProps>(), {
    prefixCls: defaultPrefixCls
})

const { prefixCls } = toRefs(props)
const appStore = useAppStore()
const isMobile = ref(false)
const isSetState = ref(false)

createAppProviderContext({ prefixCls, isMobile })

const handleRestoreState = () => {
    if (unref(isMobile)) {
        if (!unref(isSetState)) {
            isSetState.value = true
            const { menuSetting } = appStore.getProjectConfig
            const { type: menuType, mode: menuMode, collapsed: menuCollapsed } = menuSetting

            appStore.setProjectConfig({
                menuSetting: {
                    type: MenuTypeEnum.SIDEBAR,
                    mode: MenuModeEnum.INLINE
                }
            })
            appStore.setBeforeMiniInfo({ menuMode, menuCollapsed, menuType })
        }
    } else {
        if (unref(isSetState)) {
            isSetState.value = false
            const { menuMode, menuCollapsed, menuType } = appStore.getBeforeMiniInfo
            appStore.setProjectConfig({
                menuSetting: {
                    type: menuType,
                    mode: menuMode,
                    collapsed: menuCollapsed
                }
            })
        }
    }
}

// 监视屏幕大小信息的更改
createBreakpointListen(({ screenMap, sizeEnum, width }) => {
    const lgWidth = screenMap.get(sizeEnum.LG)
    if (lgWidth) {
        isMobile.value = width.value - 1 < lgWidth
    }
    handleRestoreState()
})
</script>
