import { message } from 'ant-design-vue'
import Clipboard from 'clipboard'
import type { App } from 'vue'
import { Directive } from 'vue'

const clipboardDirective: Directive = {
    beforeMount(el: any, binding: any) {
        if (binding.arg === 'success') {
            el.__clipboard_success = binding.value
        } else if (binding.arg === 'error') {
            el.__clipboard_error = binding.value
        } else {
            el.__clipboard = new Clipboard(el, {
                text: () => binding.value.toString()
            })

            el.__clipboard.on('success', (evt: any) => {
                if (el.__clipboard_success == null) return
                if (typeof el.__clipboard_success === 'string') message.success(el.__clipboard_success)
                if (typeof el.__clipboard_success === 'function') el.__clipboard_success(evt)
            })

            el.__clipboard.on('error', (evt: any) => {
                if (el.__clipboard_error == null) return
                if (typeof el.__clipboard_error === 'string') message.error(el.__clipboard_error)
                if (typeof el.__clipboard_error === 'function') el.__clipboard_error(evt)
            })
        }
    },

    updated(el, binding) {
        if (binding.arg === 'success') {
            el.__clipboard_success = binding.value
        } else if (binding.arg === 'error') {
            el.__clipboard_error = binding.value
        } else {
            el.__clipboard.text = () => binding.value.toString()
        }
    },

    unmounted(el) {
        delete el.__clipboard_success
        delete el.__clipboard_error
        el.__clipboard?.destroy()
        delete el.__clipboard
    }
}

export function setupClipboardDirective(app: App) {
    app.directive('clipboard', clipboardDirective)
}

export default clipboardDirective
