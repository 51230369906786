import { defineStore } from 'pinia'
import { getSecurityPortRanges, getSecurityProtocols } from '@/synthesize/business'

import { store } from '../index'

interface CommonDataStore{
    portRange: any[]
    protocolList: any[]
}

export const commonDataStore = defineStore({
    id: 'app-common-data',
    state: (): any => ({
        portRange: [],
        protocolList: []
    }),
    getters: {
        getPortRange(): CommonDataStore{
            return this.portRange
        },
        getProtocolList(): CommonDataStore{
            return this.protocolList
        }
    },
    actions: {
        async getCommonData() {
            const [portRange, protocolList] = await Promise.all([
                getSecurityPortRanges(),
                getSecurityProtocols()
            ])
            this.portRange = portRange
            this.protocolList = protocolList
        }
    }
})

export function commonDataStoreWithOut() {
    return commonDataStore(store)
}
