import type { App } from 'vue'
import { I18nOptions } from 'vue-i18n'
import { createI18n } from 'vue-i18n'

import { localeSetting } from '@/config/locale'
import { useLocaleStoreWithOut } from '@/store/modules/locale'

import { setHtmlPageLang, setLoadLocalePool } from './helper'

const { fallback, availableLocales } = localeSetting

export let i18n: ReturnType<typeof createI18n>

async function createI18nOptions(): Promise<I18nOptions> {
    const localeStore = useLocaleStoreWithOut()
    const locale = localeStore.getLocale
    const defaultLocal = await import(`./lang/${locale}.ts`)
    const message = defaultLocal.default?.message ?? {}

    setHtmlPageLang(locale)
    setLoadLocalePool((loadLocalePool) => {
        loadLocalePool.push(locale)
    })

    return {
        legacy: false,
        locale,
        fallbackLocale: fallback,
        messages: {
            [locale]: message
        },
        availableLocales: availableLocales,
        sync: true,
        silentTranslationWarn: true,
        missingWarn: false,
        silentFallbackWarn: true
    }
}

async function setupI18n(app: App) {
    const options = await createI18nOptions()
    i18n = createI18n(options)
    app.use(i18n)
}

export default setupI18n
