import { isArray } from 'lodash-es'
import { defineStore } from 'pinia'
import { h } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import { getRoles, getUserInfo } from '@/api/user'
import { APP_PROJECT_ID, MOA_PATH } from '@/config'
import { MOA_AUTH_INFO, ROLES_KEY, TOKEN_KEY, USER_INFO_KEY } from '@/config/cache'
import { PageEnum } from '@/enums/pageEnum'
import { RoleEnum } from '@/enums/roleEnum'
import { useI18n } from '@/hooks/web/useI18n'
import { useMessage } from '@/hooks/web/useMessage'
import { router } from '@/router'
import { PAGE_NOT_FOUND_ROUTE } from '@/router/routes/basic'
import { UserInfo } from '@/types/store'
import { clearAuthCache, getAuthCache, setAuthCache } from '@/utils/auth'
import { webCookie } from '@/utils/cache'

import { store } from '../index'
import { usePermissionStore } from './permission'

interface UserState {
    userInfo: Nullable<UserInfo>
    token?: string
    roleList: RoleEnum[]
    lastUpdateTime: number
}

export const useUserStore = defineStore({
    id: 'app-user',
    state: (): UserState => ({
        userInfo: null,
        token: undefined,
        roleList: [],
        lastUpdateTime: 0
    }),
    getters: {
        getUserInfo(): UserInfo {
            return window.getLsCacheItem(COMMON_VARS.USER_INFO_KEY)
            // return this.userInfo || getAuthCache<UserInfo>(USER_INFO_KEY) || {}
        },
        getToken(): string {
            return window.getToken()
            // return this.token || getAuthCache<string>(TOKEN_KEY) || webCookie.get(MOA_AUTH_INFO, {}).token
        },
        getRoleList(): RoleEnum[] {
            return this.roleList.length > 0 ? this.roleList : getAuthCache<RoleEnum[]>(ROLES_KEY)
        },
        getLastUpdateTime(): number {
            return this.lastUpdateTime
        }
    },
    actions: {
        setToken(token: string | undefined) {
            this.token = token ? token : ''
            setAuthCache(TOKEN_KEY, token)
        },
        setRoleList(roleList: RoleEnum[] = []) {
            this.roleList = roleList
            setAuthCache(ROLES_KEY, roleList)
        },
        setUserInfo(info: UserInfo | null) {
            this.userInfo = info
            this.lastUpdateTime = new Date().getTime()
            setAuthCache(USER_INFO_KEY, info)

            this.setToken(info?.token)
            if (info) webCookie.set(MOA_AUTH_INFO, info)
            else webCookie.remove(MOA_AUTH_INFO)
        },

        resetState() {
            this.userInfo = null
            this.token = ''
            this.roleList = []
        },

        async afterLoginAction(goHome?: boolean): Promise<UserInfo | null> {
            if (!this.getToken) return null

            // 获取用户信息
            const userInfo = await this.getUserInfoAction()

            // 获取动态路由信息
            const permissionStore = usePermissionStore()
            if (!permissionStore.isDynamicAddedRoute) {
                const routes = await permissionStore.buildRoutesAction()
                routes.forEach((route) => {
                    router.addRoute(route as unknown as RouteRecordRaw)
                })
                router.addRoute(PAGE_NOT_FOUND_ROUTE as unknown as RouteRecordRaw)
                permissionStore.setDynamicAddedRoute(true)
            }
            goHome && (await router.replace(userInfo?.homePath || PageEnum.BASE_HOME))

            return userInfo
        },

        async getUserInfoAction(): Promise<UserInfo | null> {
            if (!this.getToken) return null

            const info = await getUserInfo(this.getToken)
            const roles = await getRoles(this.getToken)

            const userInfo: UserInfo = { ...info, avatar: `${MOA_PATH}/avatar/${info.nick}-64.jpg`, roles: [] }

            if (isArray(roles)) {
                this.setRoleList(roles as RoleEnum[])
            } else {
                userInfo.roles = []
                this.setRoleList([])
            }
            this.setUserInfo(userInfo)

            return userInfo
        },

        async login(token: string, goHome?: boolean) {
            try {
                this.setToken(token)
                return this.afterLoginAction(goHome)
            } catch (error) {
                return Promise.reject(error)
            }
        },

        // 去登录--给login组件调用
        async goLogin() {
            const redirect = encodeURIComponent(window.location.href)
            window.location.href = `${MOA_PATH}/login?project=${APP_PROJECT_ID}&redirect=${redirect}`
        },

        // 退出登录
        async logout() {
            const token = encodeURIComponent(this.token || '')
            const redirect = encodeURIComponent(router.currentRoute.value.path)

            this.setToken(undefined)
            this.setUserInfo(null)
            clearAuthCache(true)
            webCookie.remove(MOA_AUTH_INFO)

            const login = new URL(router.resolve(PageEnum.BASE_LOGIN).href, window.location.origin).href
            const moaRedirect = encodeURIComponent(`${login}?redirect=${redirect}`)
            window.location.replace(`${MOA_PATH}/logout?token=${token}&project=${APP_PROJECT_ID}&redirect=${moaRedirect}`)
        },

        // 登出前确认
        confirmLoginOut() {
            const { createModal } = useMessage()
            const { t } = useI18n()
            createModal.confirm({
                iconType: 'warning',
                title: () => h('span', t('sys.app.logoutTip')),
                content: () => h('span', t('sys.app.logoutMessage')),
                onOk: async () => {
                    window.logout()
                    // await this.logout()
                }
            })
        }
    }
})

export function useUserStoreWithOut() {
    return useUserStore(store)
}
